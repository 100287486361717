import { restClient } from './../rest';
const PUBLIC_CLIENT_ID = 'account';
export const auth = (KEYCLOAK_REALM_URL, CLIENT_ID, CLIENT_SECRET, IDENTITY_API_URL, CHECKOUT_API_URL, client, updateToken, resetToken, getToken) => {
    return {
        login: login(KEYCLOAK_REALM_URL, CLIENT_ID, CLIENT_SECRET, updateToken, resetToken, me(KEYCLOAK_REALM_URL, getToken)),
        refreshToken: refreshToken(KEYCLOAK_REALM_URL, CLIENT_ID, CLIENT_SECRET, updateToken, resetToken, me(KEYCLOAK_REALM_URL, getToken)),
        signup: signup(CHECKOUT_API_URL, me(KEYCLOAK_REALM_URL, getToken), updateToken),
        signupV2: signupV2(CHECKOUT_API_URL, me(KEYCLOAK_REALM_URL, getToken), updateToken),
        logout: logout(IDENTITY_API_URL, client, resetToken, getToken),
        me: me(KEYCLOAK_REALM_URL, getToken),
        self: self(KEYCLOAK_REALM_URL, getToken),
        forgotPassword: forgotPassword(IDENTITY_API_URL),
        generateAutologinToken: generateAutologinToken(IDENTITY_API_URL, getToken),
        generateTokenFromAutologinToken: generateTokenFromAutologinToken(IDENTITY_API_URL, updateToken, resetToken),
        getTokenFromAutorizationCode: getTokenFromAutorizationCode(KEYCLOAK_REALM_URL, CLIENT_ID, CLIENT_SECRET, updateToken, resetToken, me(KEYCLOAK_REALM_URL, getToken)),
        sendMagicLink: sendMagicLink(IDENTITY_API_URL, CLIENT_ID),
        getSocialSsoUrl: getSocialSsoUrl(KEYCLOAK_REALM_URL, CLIENT_ID),
        createKeycloakSession: createKeycloakSession(KEYCLOAK_REALM_URL, CLIENT_ID, getToken),
    };
};
export default auth;
export const login = (KEYCLOAK_REALM_URL, CLIENT_ID, CLIENT_SECRET, updateToken, resetToken, me) => async ({ email, password }) => {
    try {
        const formData = new URLSearchParams();
        formData.append('grant_type', 'password');
        formData.append('client_id', CLIENT_ID);
        formData.append('client_secret', CLIENT_SECRET);
        formData.append('scope', 'openid email profile self');
        formData.append('username', email);
        formData.append('password', password);
        const rawResult = await fetch(`${KEYCLOAK_REALM_URL}/protocol/openid-connect/token`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        });
        const result = await rawResult.json();
        updateToken(result.access_token);
        const self = await me();
        if (!self)
            throw new Error('Self error');
        return {
            userId: self.userId,
            email: self.email,
            chargeBeeId: self.chargeBeeId,
            confirmed: self.confirmed,
            blocked: self.blocked,
            firstPromoterAuthToken: self.firstPromoterAuthToken,
            jwt: result.access_token,
            refresh_token: result.refresh_token,
            session_state: result.session_state,
        };
    }
    catch (e) {
        if (e.message === 'Invalid token.') {
            resetToken();
            throw e;
        }
        console.log('[login]ERROR:', e);
        throw e;
    }
};
export const getWebToken = (KEYCLOAK_REALM_URL, CLIENT_ID, CLIENT_SECRET) => async () => {
    try {
        const formData = new URLSearchParams();
        formData.append('grant_type', 'client_credentials');
        formData.append('client_id', CLIENT_ID);
        formData.append('client_secret', CLIENT_SECRET);
        formData.append('scope', 'openid email profile');
        const rawResult = await fetch(`${KEYCLOAK_REALM_URL}/protocol/openid-connect/token`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        });
        const result = await rawResult.json();
        return result.access_token;
    }
    catch (e) {
        console.log('[login]ERROR:', e);
        throw e;
    }
};
export const logout = (IDENTITY_API_URL, client, resetToken, getToken) => async () => {
    if (!getToken()) {
        return;
    }
    await restClient(getToken()).jsonPost(IDENTITY_API_URL, '/users/logout');
    resetToken();
    client.resetStore();
};
export const createKeycloakSession = (KEYCLOAK_REALM_URL, _CLIENT_ID, getToken) => async () => {
    if (!getToken()) {
        return;
    }
    await restClient(getToken(), true).jsonGet(KEYCLOAK_REALM_URL, `/browser-session/init?publicClient=${PUBLIC_CLIENT_ID}`);
    return null;
};
export const me = (KEYCLOAK_REALM_URL, getToken) => async () => {
    try {
        const result = await restClient(getToken()).jsonGet(KEYCLOAK_REALM_URL, '/protocol/openid-connect/userinfo');
        return {
            userId: result.sub,
            blocked: false,
            email: result.email,
            confirmed: result.email_verified,
            role: mappingRoles(result.roles).name,
            gaClientId: result.gaClientId,
            pushNotifications: Boolean(result.pushNotifications),
            chargeBeeId: result.chargeBeeId,
            firstPromoterAuthToken: result.firstPromoterAuthToken,
            teamId: result.teamId,
            teamRole: result.teamRole,
        };
    }
    catch (e) {
        console.log(e);
        throw e;
    }
};
export const self = (KEYCLOAK_REALM_URL, getToken) => async () => {
    try {
        const result = await restClient(getToken()).jsonGet(KEYCLOAK_REALM_URL, '/protocol/openid-connect/userinfo');
        return {
            id: result.sub,
            blocked: false,
            email: result.email,
            confirmed: result.email_verified,
            role: mappingRoles(result.roles),
            gaClientId: result.gaClientId,
            pushNotification: result.pushNotifications,
            chargeBeeId: result.chargeBeeId,
            teamId: result.teamId,
            teamRole: result.teamRole,
            firstLogin: result.firstLogin === 'true',
        };
    }
    catch (e) {
        console.log(e);
        throw e;
    }
};
export const refreshToken = (KEYCLOAK_REALM_URL, CLIENT_ID, CLIENT_SECRET, updateToken, resetToken, me) => async (refreshToken) => {
    try {
        const formData = new URLSearchParams();
        formData.append('client_id', CLIENT_ID);
        formData.append('grant_type', 'refresh_token');
        formData.append('client_secret', CLIENT_SECRET);
        formData.append('scope', 'openid email profile self');
        formData.append('refresh_token', refreshToken);
        const rawResult = await fetch(`${KEYCLOAK_REALM_URL}/protocol/openid-connect/token`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        });
        const result = await rawResult.json();
        updateToken(result.access_token);
        const self = await me();
        if (!self)
            throw new Error('Self error');
        return {
            userId: self.userId,
            email: self.email,
            chargeBeeId: self.chargeBeeId,
            blocked: self.blocked,
            confirmed: self.confirmed,
            firstPromoterAuthToken: self.firstPromoterAuthToken,
            jwt: result.access_token,
            refresh_token: result.refresh_token,
            session_state: result.session_state,
        };
    }
    catch (e) {
        if (e.message === 'Invalid token.') {
            resetToken();
            throw e;
        }
        console.log('[login]ERROR:', e);
        throw e;
    }
};
export const getTokenFromAutorizationCode = (KEYCLOAK_REALM_URL, CLIENT_ID, CLIENT_SECRET, updateToken, resetToken, me) => async (redirectUrl, code) => {
    try {
        const formData = new URLSearchParams();
        formData.append('grant_type', 'authorization_code');
        formData.append('code', code);
        formData.append('client_id', CLIENT_ID);
        formData.append('client_secret', CLIENT_SECRET);
        formData.append('redirect_uri', redirectUrl);
        const rawResult = await fetch(`${KEYCLOAK_REALM_URL}/protocol/openid-connect/token`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        });
        const result = await rawResult.json();
        updateToken(result.access_token);
        const self = await me();
        if (!self)
            throw new Error('Self error');
        return {
            userId: self.userId,
            email: self.email,
            chargeBeeId: self.chargeBeeId,
            blocked: self.blocked,
            confirmed: self.confirmed,
            firstPromoterAuthToken: self.firstPromoterAuthToken,
            jwt: result.access_token,
            refresh_token: result.refresh_token,
            session_state: result.session_state,
        };
    }
    catch (e) {
        if (e.message === 'Invalid token.') {
            resetToken();
            throw e;
        }
        console.log('[login]ERROR:', e);
        throw e;
    }
};
export const sendMagicLink = (IDENTITY_API_URL, CLIENT_ID) => async (email, redirectUrl) => {
    try {
        const result = await restClient().jsonPost(IDENTITY_API_URL, '/magic-link', {
            email: email,
            clientId: CLIENT_ID,
            redirectUrl: redirectUrl,
        });
        return result;
    }
    catch (e) {
        console.log('[login]ERROR:', e);
        throw e;
    }
};
export const forgotPassword = (IDENTITY_API_URL) => async (email) => {
    try {
        const result = await restClient().jsonPost(IDENTITY_API_URL, '/users/forgot-password', { email });
        return result;
    }
    catch (e) {
        console.log(e);
        throw e;
    }
};
export const generateAutologinToken = (IDENTITY_API_URL, getToken) => async () => {
    try {
        const token = getToken();
        if (!token)
            throw new Error('Token not found');
        const result = await restClient().jsonPost(IDENTITY_API_URL, '/autologin/encode', { token });
        if (!result)
            throw new Error('Token not found');
        return result.token;
    }
    catch (e) {
        console.log(e);
        throw e;
    }
};
export const generateTokenFromAutologinToken = (IDENTITY_API_URL, updateToken, resetToken) => async (token) => {
    try {
        const result = await restClient().jsonPost(IDENTITY_API_URL, '/autologin/decode', { token });
        if (!result)
            throw new Error('Token not found');
        updateToken(result.token);
        return result.token;
    }
    catch (e) {
        console.log(e);
        resetToken();
        throw e;
    }
};
export const signup = (CHECKOUT_API_URL, me, updateToken) => async (data) => {
    try {
        const result = await fetch(`${CHECKOUT_API_URL}/signup/register`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ account: data }),
        });
        const status = result.status;
        const text = await result.text();
        if (!text || text.trim() === '') {
            throw new Error("Errore durante la creazione dell'account");
        }
        const response = { status: status, body: JSON.parse(text) };
        if (response.status === 200) {
            updateToken(response.body.access_token);
            const self = await me();
            if (!self)
                throw new Error('Self error');
            return {
                status: 200,
                body: {
                    userId: self.userId,
                    email: self.email,
                    chargeBeeId: self.chargeBeeId,
                    confirmed: self.confirmed,
                    blocked: self.blocked,
                    firstPromoterAuthToken: self.firstPromoterAuthToken,
                    jwt: response.body.access_token,
                },
            };
        }
        return response;
    }
    catch (e) {
        console.log(e);
        throw e;
    }
};
export const signupV2 = (CHECKOUT_API_URL, me, updateToken) => async (data) => {
    try {
        const result = await fetch(`${CHECKOUT_API_URL}/signup/register-v2`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify([data]),
        });
        const status = result.status;
        if (status === 409) {
            return {
                status: 409,
                body: { errorCode: 'Email già registrata' },
            };
        }
        const text = await result.text();
        if (!text || text.trim() === '') {
            throw new Error("Errore durante la creazione dell'account");
        }
        const response = { status: status, body: JSON.parse(text) };
        if (response.status === 200) {
            updateToken(response.body[0].data.login.access_token);
            const self = await me();
            if (!self)
                throw new Error('Self error');
            return {
                status: 200,
                body: {
                    userId: self.userId,
                    email: self.email,
                    chargeBeeId: self.chargeBeeId,
                    confirmed: self.confirmed,
                    blocked: self.blocked,
                    firstPromoterAuthToken: self.firstPromoterAuthToken,
                    jwt: response.body[0].data.login.access_token,
                },
            };
        }
        return response;
    }
    catch (e) {
        console.log(e);
        throw e;
    }
};
export const getSocialSsoUrl = (KEYCLOAK_REALM_URL, CLIENT_ID) => async (social, redirectUrl) => {
    const url = new URL(`${KEYCLOAK_REALM_URL}/protocol/openid-connect/auth`);
    url.searchParams.append('client_id', CLIENT_ID);
    url.searchParams.append('redirect_uri', redirectUrl);
    url.searchParams.append('response_type', `code`);
    url.searchParams.append('scope', 'openid profile email self');
    url.searchParams.append('kc_idp_hint', social);
    return url.href;
};
const mappingRoles = (roles) => {
    for (const role of roles) {
        switch (role) {
            case 'free':
                return {
                    id: 3,
                    type: 'free',
                    name: 'Free',
                };
            case 'authenticated':
                return {
                    id: 1,
                    type: 'authenticated',
                    name: 'Authenticated',
                };
            case 'corporate':
                return {
                    id: 5,
                    type: 'corporate',
                    name: 'Corporate',
                };
            case 'admin':
                return {
                    id: 4,
                    type: 'admin',
                    name: 'Admin',
                };
        }
    }
    return {
        id: 3,
        type: 'free',
        name: 'Free',
    };
};
