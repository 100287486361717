import api from './api'
import * as TE from 'fp-ts/TaskEither'
import { pipe } from 'fp-ts/function'
import { Course, Path } from '@learnn/sdk/src/api/paths/types'
import { VerifiedState } from '@learnn/sdk/src/api/quiz'
import env from '../env.json'
import { Observable } from 'rxjs'

export type CourseStatus = 'to_start' | 'to_finish' | 'competed' | 'to_verify' | 'verified'
export type CourseWithProgress = Course & {
  verified: boolean
  progress: number
  status: CourseStatus
}
export type PathWithProgress = Path &
  Partial<{ courses: Partial<CourseWithProgress>[]; verified: boolean, progress: number }>


const applyEngagement =
  (engagement: any, verifiedStates: VerifiedState[]) =>
  (course: Course): CourseWithProgress => {
    const verified =
      verifiedStates.filter(el => el.courseId === course.id).length > 0
        ? verifiedStates.filter(el => el.courseId === course.id)[0].quizStatus.passed
        : false
    const progress = engagement[course.id] ? engagement[course.id].progress : 0

    const getStatus = (): CourseStatus => {
      if (progress === 0) return 'to_start'
      if (progress < env.COURSE_COMPLETED_THRESHOLD) return 'to_finish'
      if (!verified && course.type.slug === 'course') return 'to_verify'
      if (verified && course.type.slug === 'course') return 'verified'
      return 'competed'
    }

    return {
      ...course,
      verified: verified,
      progress: progress,
      status: getStatus(),
    }
  }

export const getPath =
  (slug: string, userId: string) => async (): Promise<Observable<PathWithProgress>> => {
    const { paths, engagement, quiz } = await api

    return new Observable(subscriber => {
      pipe(
        slug,
        paths.getPath,
        TE.map(path => {
          subscriber.next(path)
          return path
        }),
        TE.chainW(path => {
          return TE.tryCatch<Error, PathWithProgress>(
            () =>
              (async () => {
                const { coursesEngagement } = await engagement.getHome(
                  path.courses.map(el => el.id.toString()),
                )
                const quizStatus = await quiz.getVerifiedStatus(userId)
                let pathVerified
                try {
                  await quiz.getPathAttempt(userId, path.id)
                  pathVerified = true
                } catch (e) {
                  pathVerified = false
                }
                const courses = path.courses.map(applyEngagement(coursesEngagement, quizStatus))
                const mandatoryCourses = courses.filter(c => c.type.slug === 'course')
                const totalProgressSum = Math.trunc(mandatoryCourses.reduce((total: number, c: Partial<CourseWithProgress>) => c.progress ? total+c.progress : total, 0))
                const totalCourses = mandatoryCourses.length;
                const progress = Math.trunc(totalProgressSum / totalCourses)
                const pathWithProgress = {
                  ...path,
                  courses,
                  verified: pathVerified,
                  progress: progress
                }
                subscriber.next(pathWithProgress)
                return pathWithProgress
              })(),
            r => {
              return new Error(String(r))
            },
          )
        }),
        TE.mapLeft(e => {
          subscriber.error(e)
          return e
        }),
      )()
    })
  }

export const getPaths =
(_userId: string) => async (): Promise<Observable<PathWithProgress[]>> => {
  const { paths } = await api


  return new Observable(subscriber => {
    pipe(
      paths.getPaths(),
      TE.map((paths) => {
        const sortedPaths = paths.sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1)
        subscriber.next(sortedPaths)
        return paths
      }),
      /*
      TODO add progresses to path card 
      TE.chainW(paths => {
        return TE.tryCatch<Error, PathWithProgress[]>(
          () =>
            (async () => {
              let pathsWithProgress: PathWithProgress[] = paths
              for (const path of paths) {
                const { coursesEngagement } = await engagement.getHome(
                  path.courses.map(el => el.id.toString()),
                )
                const quizStatus = await quiz.getVerifiedStatus(userId)
                let pathVerified
                try {
                  await quiz.getPathAttempt(userId, path.id)
                  pathVerified = true
                } catch (e) {
                  pathVerified = false
                }
                const courses = path.courses.map(applyEngagement(coursesEngagement, quizStatus))
                const pathWithProgress = {
                  ...path,
                  courses,
                  verified: pathVerified,
                  progress: courses.reduce((total: number, c: Partial<CourseWithProgress>) => c.progress ? total+c.progress : total, 0)
                }

                pathsWithProgress = [
                  ...pathsWithProgress.filter(x => x.id !== pathWithProgress.id),
                  pathWithProgress
                ]
                pathsWithProgress = pathsWithProgress.sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1)
                subscriber.next(pathsWithProgress)
              }
              return pathsWithProgress;
            })(),
          r => {
            return new Error(String(r))
          },
        )
      }),
      */
      TE.mapLeft(e => {
        subscriber.error(e)
        return e
      }),
    )()
  })
}
