import { useContext, useEffect, useState } from 'react'
import style from './integrationsScreen.module.css'

import Container from 'react-bootstrap/Container'

import Loader from 'react-loader-spinner'
import initApi from '../../controllers/api'
import { useBodyClass } from '../../utils/hooks'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import type { UserSocialProvider } from '@learnn/sdk/src/api/account'
import appleIcon from '../../assets/images/icons/apple_logo.svg'
import gmailIcon from '../../assets/images/icons/gmail.png'
import { HorizontalStack, VerticalStack } from '@learnn/designn'

export const Integrations = ({
  code,
  theme = 'light',
}: {
  code: string
  theme: 'light' | 'dark'
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isLinkingProvider, setIsLinkingProvider] = useState<string>('')
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId ?? ''

  const [socialProviders, setSocialProviders] = useState<UserSocialProvider[]>()

  const fetchData = async () => {
    try {
      const api = await initApi
      const res = await api.account.getSocialProviders(userId)
      setSocialProviders(res)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [])

  if (theme === 'dark') {
    useBodyClass('app')
  }

  const handleSocialLinking = async (providerId: string, link: boolean = true) => {
    setIsLinkingProvider(providerId)

    try {
      const api = await initApi

      if (link) {
        await api.account.linkSocialProvider(providerId, window.location.href)
      } else {
        await api.account.unlinkSocialProvider(userId, providerId)
      }

      await fetchData()

      setTimeout(() => {
        setIsLinkingProvider('')
      }, 2000)
    } catch (error) {
      setIsLinkingProvider('')
    }
  }

  if (loading || !socialProviders) {
    return (
      <div className={!code ? style.loaderWrapDark : style.loaderWrap}>
        <Loader type='TailSpin' color={!code ? 'white' : 'black'} height={60} width={60} />
      </div>
    )
  }

  const providerName = {
    apple: 'Apple',
    facebook: 'Facebook',
    google: 'Google',
    linkedin: 'LinkedIn',
  }

  const providerDescription = {
    apple: 'Login con Apple',
    facebook: 'Login con Facebook',
    google: 'Login con Google',
    linkedin: 'Login con LinkedIn',
  }

  const providerLogo = {
    apple: appleIcon,
    facebook: '',
    google: gmailIcon,
    linkedin: '',
  }

  return (
    <>
      <Container className={style.container}>
        {socialProviders.map(
          ({ enabled, linked, providerId, userName }) =>
            //@ts-ignore //TODO
            providerName[providerId] &&
            enabled && (
              <HorizontalStack key={providerId} className={style.providerContainer}>
                <HorizontalStack>
                  <div data-provider={providerId} className={style.providerIconWrapper}>
                    {/* @ts-ignore //TODO */}
                    <img className={style.providerIcon} src={providerLogo[providerId]}></img>
                  </div>
                  <VerticalStack>
                    {/* @ts-ignore //TODO */}
                    <span className={style.providerName}>{providerName[providerId]}</span>
                    {/* @ts-ignore //TODO */}
                    {providerDescription[providerId] && (
                      <span className={style.providerDescription}>
                        {/* @ts-ignore //TODO */}
                        {providerDescription[providerId]}
                      </span>
                    )}
                  </VerticalStack>
                </HorizontalStack>
                <VerticalStack>
                  <div className={style.linkCtaWrapper}>
                    <button
                      className={style.linkCta}
                      data-linked={linked}
                      disabled={isLinkingProvider !== ''}
                      onClick={() => handleSocialLinking(providerId, !linked)}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          minWidth: '80px',
                          textAlign: 'center',
                        }}>
                        {isLinkingProvider === providerId ? (
                          <Loader
                            type='TailSpin'
                            color={linked ? 'white' : 'black'}
                            height={20}
                            width={20}
                            style={{ margin: '0 auto' }}
                          />
                        ) : (
                          <span>{linked ? 'Scollega' : 'Connetti'}</span>
                        )}
                      </div>
                    </button>
                  </div>

                  {linked && (
                    <em
                      style={{
                        marginTop: '10px',
                      }}>
                      <span className={style.linkedDescription}>Collegato a {userName}</span>
                    </em>
                  )}

                  {/* {error && (
                    <span
                      className={style.linkedDescription}
                      style={{
                        color: 'red',
                        marginTop: '10px',
                        textAlign: 'right',
                      }}>
                      {error}
                    </span>
                  )} */}
                </VerticalStack>
              </HorizontalStack>
            ),
        )}
      </Container>
    </>
  )
}
