import { FC } from 'react'
import { matchQuery } from '../../utils/matchQuery'
import style from './courseStatusCard.module.css'
import { ReactComponent as VerifyOutlineIcon } from '../../assets/images/icons/verified_outline.svg'
import { ReactComponent as VerifyIcon } from '../../assets/images/icons/verified.svg'
import env from '../../env.json'
import { CircularProgress } from '../../components/CircularProgress'
import { useQuery } from 'react-query'
import { getCourseVerifiedStatus } from '../../controllers/Quiz'
import { useHistory } from 'react-router-dom'
import { Button, HorizontalStack, Title, VerticalStack, useTheme, Text, Box } from '@learnn/designn'
import { ResponsiveImage } from '../ResponsiveImage'
import { toReadableDate } from '../../utils/time'
import trophyImage from '../../assets/images/trophy.svg'

type Variant = 'medium' | 'small'
type CourseStatusCardProps = {
  userId: string
  courseId: string
  courseProgressPercentage: number
  variant?: 'medium' | 'small'
}
export const CourseStatusCard: FC<CourseStatusCardProps> = ({
  userId,
  courseId,
  courseProgressPercentage,
  variant = 'medium',
}) => {
  const verifiedStatusQuery = useQuery(['verifiedAvailability', courseId], () =>
    getCourseVerifiedStatus(userId, courseId),
  )
  const { spacing } = useTheme()
  const history = useHistory()

  const switchVariant = (options: { [key in Variant]: any }) => {
    return options[variant]
  }

  return (
    <>
      {matchQuery(verifiedStatusQuery, {
        loading: () => <></>,
        error: () => <></>,
        success: data => {
          switch (data.status) {
            case 'passed':
              return (
                <VerticalStack
                  borderRadius='20px'
                  background='linear-gradient(270deg, #2a272c 49.04%, rgba(39, 39, 44, 0.52) 100%)'
                  p={spacing.space_5}
                  justifyContent='center'
                  alignItems='center'>
                  <VerifyIcon className={style.icon} style={{ fill: 'white' }} />
                  <Title variant='headingSm' mt={spacing.space_2} mb={spacing.space_5}>
                    Verifica ottenuta
                  </Title>
                  <Button
                    variant='primary'
                    label='Visualizza la verifica'
                    onPress={() => {
                      window.open(
                        `${env.QUIZ_CERTIFICATION_URL}${data.quizStatus.attempts.filter(x => x.passed)[0].attemptId
                        }`,
                        'blank',
                      )
                    }}
                  />
                </VerticalStack>
              )
            case 'failed':
            case 'available':
              return (
                <VerticalStack
                  borderRadius='20px'
                  background='linear-gradient(270deg, #2a272c 49.04%, rgba(39, 39, 44, 0.52) 100%)'
                  p={spacing.space_5}
                  justifyContent='center'
                  alignItems='center'>
                  <VerifyOutlineIcon className={style.icon} style={{ fill: 'white' }} />
                  {data.retryDate < new Date(Date.now()) ? (
                    <>
                      <Title variant='headingSm' mt={spacing.space_2} mb={spacing.space_5}>
                        Verifica disponibile
                      </Title>
                      <Button
                        variant='primary'
                        label='Tenta la verifica'
                        onPress={() => {
                          history.push(`/test/corso/${courseId}`)
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Title variant='headingSm' mt={spacing.space_2} mb={spacing.space_3}>
                        Verifica bloccata
                      </Title>
                      <Text
                        variant='bodySm'
                        lineHeightScale='5'>{`Prossimo tentativo: ${toReadableDate(
                          data.retryDate,
                        )}.`}</Text>
                    </>
                  )}
                </VerticalStack>
              )
            case 'unavailable':
              return (
                <HorizontalStack
                  borderRadius='20px'
                  background='linear-gradient(270deg, #2a272c 49.04%, rgba(39, 39, 44, 0.52) 100%)'
                  p={spacing.space_5}
                  justifyContent='center'
                  alignItems='center'>
                  <VerticalStack mr={spacing.space_5}>
                    <CircularProgress
                      progress={courseProgressPercentage}
                      size={switchVariant({
                        medium: 80,
                        small: 50,
                      })}
                      labelSize={switchVariant({
                        medium: '20px',
                        small: '12px',
                      })}
                      trackWidth={switchVariant({
                        medium: 3,
                        small: 2,
                      })}
                      indicatorWidth={3.5}
                    />
                  </VerticalStack>
                  <VerticalStack flex={1}>
                    <Title
                      variant={switchVariant({
                        medium: 'headingMd',
                        small: 'headingSm',
                      })}>
                      Raggiungi l'{env.COURSE_COMPLETED_THRESHOLD}% per accedere alla verifica
                    </Title>
                  </VerticalStack>
                  <Box
                    display={{
                      _: 'none',
                      small: 'flex'
                    }}>
                    <VerticalStack
                      width={switchVariant({
                        medium: '100px',
                        small: '80px',
                      })}>
                      <ResponsiveImage wrapperClassName={style.image} src={trophyImage} />
                    </VerticalStack>
                  </Box>
                </HorizontalStack>
              )
          }
        },
      })}
    </>
  )
}
