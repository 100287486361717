import { FC, useContext } from "react";
import cx from "classnames";
import { ComingContent } from "../../screens/ComingSoonScreen/types";
import { ResponsiveImage } from "../ResponsiveImage";
import useLinkNavigation from "../../utils/useLinkNavigation";
import { GlobalProviderContext } from "../GlobalProvider";
import { extractConfiguration } from "../../utils/data";
import jobLogo from "../../assets/images/jobLogo.svg";

import style from "./jobCard.module.css";

export interface IJobCard {
  job: ComingContent;

  onClick?: Function;
}

export const JobCard: FC<IJobCard> = ({ job, onClick }) => {
  const linkNavigation = useLinkNavigation();
  const url = linkNavigation.parseUrl(job.contentUrl);
  const globalContext = useContext(GlobalProviderContext);
  const configuration = extractConfiguration(globalContext);
  const configJobLogo = configuration?.jobLogo;

  return (
    <div
      className={cx([url ? style.clickableCard : style.card])}
      onClick={() => {
        if (onClick) {
          onClick("coming_soon_jobs", url);
        }

        if (url) {
          window.open(url);
        }
      }}>
      <div className={style.imageContainer}>
        <ResponsiveImage
          wrapperClassName={style.borderedImage}
          src={
            job.coverImageUrl ?? "https://stack-vod-learnn-source-9043g70p82tj.s3.eu-west-3.amazonaws.com/i_jobs_template_04_01dd4bba93.jpg"
          }
        />
        <div className={job.releaseDateObject ? style.overlayContainer : style.overlayContainerSingle}>
          {job.releaseDateObject ? (
            <div className={style.releaseDate}>
              <h4 className={style.dateMonth}>{job.releaseDateObject.month}</h4>
              <h4 className={style.dateDay}>{job.releaseDateObject.day}</h4>
            </div>
          ) : (
            <div></div>
          )}

          <img className={style.badgeImage} src={configJobLogo ?? jobLogo} />
        </div>
      </div>

      <div className={style.header}>
        <p className={style.title}>{job.title}</p>
        <p className={style.description}>{job.description ?? "Prima candidatura sul sito"}</p>
      </div>
    </div>
  );
};
