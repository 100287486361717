"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.analytics = void 0;
const utils_1 = require("./utils");
const analytics = (provider, route_name) => {
    const create_at = Date.now();
    const route_name_prop = route_name ? { route_name } : {};
    const logEvent = async (type, params) => (0, utils_1.suppressError)(provider.logEvent(type, {
        ...params,
        ...route_name_prop,
        ...{ create_at },
    }));
    return {
        logViewCourseSummaryFor: async (course_id, seconds, { course_title, authors, categories_ids, total_lessons, total_modules, course_progress, }) => await logEvent(`view_course_summary_for_${seconds}s`, {
            ...{ course_id },
            ...{
                course_title,
                authors,
                categories_ids,
                total_modules,
                total_lessons,
                course_progress,
            },
        }),
        logCourseOpened: async (course_id, { course_title, course_type, authors, course_progress, categories_ids, total_lessons, total_modules, }) => await logEvent('course_opened', {
            ...{ course_id },
            ...{
                course_title,
                course_type,
                authors,
                course_progress,
                categories_ids,
                total_lessons,
                total_modules,
            },
        }),
        logNewCourseStarted: async (course_id, { course_title, course_type, authors, categories_ids, total_lessons, total_modules, course_progress, }) => await logEvent('new_course_started', {
            ...{ course_id },
            ...{
                course_title,
                course_type,
                authors,
                categories_ids,
                total_modules,
                total_lessons,
                course_progress,
            },
        }),
        logCourseCompleted: async (course_id, { course_title, course_type, authors, categories_ids, total_lessons, total_modules, course_progress, }) => logEvent('course_completed', {
            ...{ course_id },
            ...{
                course_title,
                course_type,
                authors,
                categories_ids,
                total_modules,
                total_lessons,
                course_progress,
            },
        }),
        logNewModuleStarted: async (course_id, module_id, { course_title, course_type, module_title, authors, categories_ids, total_modules, total_lessons, course_progress, module_progress, }) => logEvent('new_module_started', {
            ...{ course_id, module_id },
            ...{
                course_title,
                course_type,
                module_title,
                authors,
                categories_ids,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
            },
        }),
        logModuleCompleted: async (course_id, module_id, { course_title, course_type, module_title, authors, categories_ids, total_modules, total_lessons, course_progress, module_progress, }) => logEvent('module_completed', {
            ...{ course_id, module_id },
            ...{
                course_title,
                course_type,
                module_title,
                authors,
                categories_ids,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
            },
        }),
        logLessonOpened: async (course_id, module_id, lesson_id, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('lesson_opened', {
            ...{ course_id, module_id, lesson_id },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logLessonSave: async (lesson_id) => logEvent('lesson_save', {
            ...{ lesson_id },
        }),
        logLessonUnsave: async (lesson_id) => logEvent('lesson_unsave', {
            ...{ lesson_id },
        }),
        logCourseSave: async (course_id) => logEvent('course_save', {
            ...{ course_id },
        }),
        logCourseUnsave: async (course_id) => logEvent('course_unsave', {
            ...{ course_id },
        }),
        logLessonStarted: async (course_id, module_id, lesson_id, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('lesson_started', {
            ...{ course_id, module_id, lesson_id },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logLessonProgress: async (course_id, module_id, lesson_id, progress, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('lesson_progress', {
            ...{ course_id, module_id, lesson_id, progress },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logLessonCompleted: async (course_id, module_id, lesson_id, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('lesson_completed', {
            ...{ course_id, module_id, lesson_id },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logPlay: async (course_id, module_id, lesson_id, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('video_play', {
            ...{ course_id, module_id, lesson_id },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logStop: async (course_id, module_id, lesson_id, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('video_stop', {
            ...{ course_id, module_id, lesson_id },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logShowVideoDescription: async (course_id, module_id, lesson_id, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('video_show_description', {
            ...{ course_id, module_id, lesson_id },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logVideoFullscreen: async (course_id, module_id, lesson_id, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('video_fullscreen', {
            ...{ course_id, module_id, lesson_id },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logVideoChangeSpeed: async (course_id, module_id, lesson_id, speed, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('video_change_speed', {
            ...{ course_id, module_id, lesson_id, speed },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logVideoSwitchMode: async (course_id, module_id, lesson_id, mode, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('video_switch_' + mode, {
            ...{ course_id, module_id, lesson_id },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logVideoForward15: async (course_id, module_id, lesson_id, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('video_forward_15', {
            ...{ course_id, module_id, lesson_id },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logVideoBack15: async (course_id, module_id, lesson_id, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('video_back_15', {
            ...{ course_id, module_id, lesson_id },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logVideoSeek: async (course_id, module_id, lesson_id, seek, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent('video_seek', {
            ...{ course_id, module_id, lesson_id, seek },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logVideoToggleSubtitles: async (course_id, module_id, lesson_id, enabled, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent(`video_${enabled ? 'enable' : 'disable'}_subtitles`, {
            ...{ course_id, module_id, lesson_id },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logVideoShare: async (course_id, module_id, lesson_id, { course_title, course_type, module_title, lesson_title, authors, categories_ids, lesson_type, total_modules, total_lessons, course_progress, module_progress, lesson_progress, }) => logEvent(`video_share_click`, {
            ...{ course_id, module_id, lesson_id },
            ...{
                course_title,
                course_type,
                module_title,
                lesson_title,
                authors,
                categories_ids,
                lesson_type,
                total_modules,
                total_lessons,
                course_progress,
                module_progress,
                lesson_progress,
            },
        }),
        logContentShare: async (content_type, medium, url) => logEvent(`${content_type}_share`, {
            ...{ content_type, medium, url },
        }),
        logTrailerClick: async (course_id, { course_title, course_type, authors, categories_ids, total_modules, total_lessons, course_progress, }) => logEvent('trailer_click', {
            ...{ course_id },
            ...{
                course_title,
                course_type,
                authors,
                categories_ids,
                total_modules,
                total_lessons,
                course_progress,
            },
        }),
        logLessonUtile: async (oldState, newState, lesson_id) => {
            if (oldState === 'like')
                logEvent('lesson_utile_remove', {
                    ...{ lesson_id },
                });
            if (oldState === 'dislike')
                logEvent('lesson_nonutile_remove', {
                    ...{ lesson_id },
                });
            if (newState === 'like')
                logEvent('lesson_utile_add', {
                    ...{ lesson_id },
                });
            if (newState === 'dislike')
                logEvent('lesson_nonutile_add', {
                    ...{ lesson_id },
                });
        },
        logCourseUtile: async (oldState, newState, course_id) => {
            if (oldState === 'like')
                logEvent('course_utile_remove', {
                    ...{ course_id },
                });
            if (oldState === 'dislike')
                logEvent('course_nonutile_remove', {
                    ...{ course_id },
                });
            if (newState === 'like')
                logEvent('course_utile_add', {
                    ...{ course_id },
                });
            if (newState === 'dislike')
                logEvent('course_nonutile_add', {
                    ...{ course_id },
                });
        },
        logCourseAddUtile: async (course_id) => logEvent('course_utile_add', {
            ...{ course_id },
        }),
        logCourseRemoveUtile: async (course_id) => logEvent('course_utile_remove', {
            ...{ course_id },
        }),
        logCourseAddNonUtile: async (course_id) => logEvent('course_nonutile_add', {
            ...{ course_id },
        }),
        logCourseRemoveNonUtile: async (course_id) => logEvent('course_nonutile_remove', {
            ...{ course_id },
        }),
        logCreateNote: async (course_id, module_id, lesson_id) => logEvent('create_note', {
            ...{ course_id, module_id, lesson_id },
        }),
        logSaveNote: async (course_id, module_id, lesson_id) => logEvent('save_note', {
            ...{ course_id, module_id, lesson_id },
        }),
        logShareNote: async (course_id, module_id, lesson_id) => logEvent('share_note', {
            ...{ course_id, module_id, lesson_id },
        }),
        logSidebarToggle: async (on, course_id, module_id, lesson_id) => logEvent('sidebar_toggle', { ...{ on, course_id, module_id, lesson_id } }),
        logSidebarShowLessons: async (course_id, module_id, lesson_id) => logEvent('sidebar_show_lessons', {
            ...{ course_id, module_id, lesson_id },
        }),
        logSidebarShowNotes: async (course_id, module_id, lesson_id) => logEvent('sidebar_show_notes', {
            ...{ course_id, module_id, lesson_id },
        }),
        logSidebarShowTranscipt: async (course_id, module_id, lesson_id) => logEvent('sidebar_show_transcript', {
            ...{ course_id, module_id, lesson_id },
        }),
        logSidebarShowResources: async (course_id, module_id, lesson_id) => logEvent('sidebar_show_resources', {
            ...{ course_id, module_id, lesson_id },
        }),
        logTranscriptBlockClick: async (course_id, module_id, lesson_id, view_time) => logEvent('transcript_block_click', {
            ...{ course_id, module_id, lesson_id, view_time },
        }),
        logSelectProfileSection: async (section_name) => logEvent('select_profile_section', {
            ...{
                section_name,
            },
        }),
        logSearchKeyword: async (keyword) => logEvent('search_keyword', {
            ...{
                keyword,
            },
        }),
        logSearchCategory: async (category_name) => logEvent('search_category', {
            ...{
                category_name,
            },
        }),
        logSelectSearchSection: async (section_name) => logEvent('select_search_section', {
            ...{
                section_name,
            },
        }),
        logSelectSearchItem: async (keyword, contentType, item_id, item_title, item_position) => logEvent('select_search_item', {
            ...{
                keyword,
                content_type: contentType,
                item_id: item_id,
                item_title: (0, utils_1.truncateString)(item_title, 100),
                item_position: item_position,
            },
        }),
        logNotificationReceived: async ({ id, title, message, link, }) => logEvent('notification_received', {
            ...{
                id,
                title,
                message: (0, utils_1.truncateString)(message, 100),
                link: (0, utils_1.truncateString)(link, 100),
            },
        }),
        logNotificationOpened: async ({ id, title, message, link, }) => logEvent('notification_opened', {
            ...{
                id,
                title,
                message: (0, utils_1.truncateString)(message, 100),
                link: (0, utils_1.truncateString)(link, 100),
            },
        }),
        logLogin: async (method) => await (0, utils_1.suppressError)(provider.logLogin(method)),
        logSelectContent: async (contentType, itemId) => (0, utils_1.suppressError)(provider.logSelectContent(contentType, (0, utils_1.truncateString)(itemId, 100))),
        logSelectItem: async (contentType, item_id, item_title, list_name) => logEvent('select_item', {
            ...{
                content_type: contentType,
                item_id: (0, utils_1.truncateString)(item_id, 100),
                item_title: (0, utils_1.truncateString)(item_title, 100),
                list_name,
            },
        }),
        logExpandCourseModule: async (module_id, module_title) => logEvent('expand_course_module', {
            ...{
                module_id: (0, utils_1.truncateString)(module_id, 100),
                module_title: (0, utils_1.truncateString)(module_title, 100),
            },
        }),
        logContinueWatching: async (lesson_id, { lesson_title, lesson_duration, lesson_progress, call_to_action, }) => logEvent('continue_watching', {
            ...{
                lesson_id,
            },
            ...{
                lesson_title: (0, utils_1.truncateString)(lesson_title, 100),
                lesson_duration,
                lesson_progress,
                call_to_action,
            },
        }),
        logSelectSliderCourse: async (course_id, { slider_title, course_title, course_type, course_duration, course_author, }) => logEvent('select_course_from_slider', {
            ...{
                course_id,
            },
            ...{
                slider_title: (0, utils_1.truncateString)(slider_title, 100),
                course_title: (0, utils_1.truncateString)(course_title, 100),
                course_type,
                course_duration,
                course_author,
            },
        }),
        logSelectSliderLesson: async (lesson_id, { slider_title, lesson_title, lesson_duration, }) => logEvent('select_lesson_from_slider', {
            ...{
                lesson_id,
            },
            ...{
                slider_title: (0, utils_1.truncateString)(slider_title, 100),
                lesson_title: (0, utils_1.truncateString)(lesson_title, 100),
                lesson_duration,
            },
        }),
        logSelectListCourse: async (lesson_id, { lesson_title, lesson_duration, lesson_author, }) => logEvent('select_list_course', {
            ...{
                lesson_id,
            },
            ...{
                lesson_title: (0, utils_1.truncateString)(lesson_title, 100),
                lesson_duration,
                lesson_author,
            },
        }),
        logSelectLesson: async (lesson_id, { lesson_title, lesson_progress, lesson_duration, }) => logEvent('select_lesson', {
            ...{
                lesson_id,
            },
            ...{
                lesson_title: (0, utils_1.truncateString)(lesson_title, 100),
                lesson_duration,
                lesson_progress,
            },
        }),
        logQuizStarted: async (attempt_id, data) => logEvent('quiz_started', {
            ...{
                attempt_id,
            },
            ...data,
        }),
        logQuizSubmitted: async (attempt_id, data) => logEvent('quiz_submitted', {
            ...{
                attempt_id,
            },
            ...data,
        }),
        logQuizFailed: async (attempt_id, data) => logEvent('quiz_failed', {
            ...{
                attempt_id,
            },
            ...data,
        }),
        logQuizPassed: async (attempt_id, data) => logEvent('quiz_passed', {
            ...{
                attempt_id,
            },
            ...data,
        }),
        logInfiniteQuizStarted: async (attempt_id) => logEvent('infinite_quiz_started', {
            ...{
                attempt_id,
            },
        }),
        logInfiniteQuizEnd: async (attempt_id) => logEvent('infinite_quiz_end', {
            ...{
                attempt_id,
            },
        }),
        logInfiniteQuizAnswer: async (attempt_id, question_id, user_answer_id, correct) => logEvent('infinite_quiz_answer', {
            attempt_id,
            question_id,
            user_answer_id,
            correct,
        }),
        logInfiniteQuizSkip: async (attempt_id, question_id) => logEvent('infinite_quiz_skip', {
            attempt_id,
            question_id,
        }),
        logInfiniteQuizGoalAchieved: async (attempt_id, answers_strike) => logEvent('infinite_quiz_goal_achieved', {
            attempt_id,
            answers_strike,
        }),
        logNavigateTo: async (url, { is_external, content_type, content_id, }) => logEvent('navigate_to', {
            ...{ url: (0, utils_1.truncateString)(url, 100) },
            ...{
                is_external: is_external === true ? true : false,
                content_id,
                content_type,
            },
        }),
        logLogout: async () => logEvent('logout', {}),
        logToggleNotification: async (on) => logEvent('toggle_notification', { on }),
        logChangePassword: async () => logEvent('change_password', {}),
        logUpdateAccountInfo: async () => logEvent('update_account_info', {}),
        logSelectSection: async (section_name) => logEvent('select_section', {
            ...{
                section_name,
            },
        }),
        logCommunityOpen: async () => logEvent('community_open', {}),
        logCommunityOpenCourse: async (course_id) => logEvent('community_open_course', {
            ...{ course_id },
        }),
        logCommunityOpenLesson: async (course_id, lesson_id) => logEvent('community_open_lesson', {
            ...{ course_id, lesson_id },
        }),
        logEvent,
        logScreenView: async (screenClass, screenName) => (0, utils_1.suppressError)(provider.logScreenView(screenClass, screenName)),
        setUserId: async (id) => (0, utils_1.suppressError)(provider.setUserId(id)),
        setUserProperties: async (properties) => (0, utils_1.suppressError)(provider.setUserProperties(properties)),
        setUserEmail: async (email) => (0, utils_1.suppressError)(provider.setUserEmail(email)),
        setUserProperty: async (name, value) => (0, utils_1.suppressError)(provider.setUserProperty(name, value)),
        name: provider.name,
    };
};
exports.analytics = analytics;
