import { IFrame } from '../../components/IFrame/IFrame'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

export const MakeCommunityScreen = () => {
  return (
    <AppLayout>
      <IFrame url='https://learnn.com/int/make/community/' fullHeight />
    </AppLayout>
  )
}
