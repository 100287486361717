import { OramaClient } from '@oramacloud/client';
const getSearchClients = (COURSES_ENDPOINT, LESSONS_ENDPOINT, PATHS_ENDPOINT, coursesApiKey, lessonsApiKey, pathsApiKey) => {
    const coursesIndex = new OramaClient({
        endpoint: COURSES_ENDPOINT,
        api_key: coursesApiKey,
    });
    const lessonsIndex = new OramaClient({
        endpoint: LESSONS_ENDPOINT,
        api_key: lessonsApiKey,
    });
    const pathsIndex = new OramaClient({
        endpoint: PATHS_ENDPOINT,
        api_key: pathsApiKey,
    });
    return { coursesIndex, lessonsIndex, pathsIndex };
};
export default getSearchClients;
