import { FC, useContext } from 'react';
import style from './courseCard.module.css';
import { MyLearnnButton } from '../MyLearnnButton';
import { CourseBaseInfo, CourseBaseInfoEngagement } from '@learnn/sdk/dist/api/course';
import { CourseCard as CourseCardDS } from '@learnn/designn';
import { GlobalProviderContext } from '../GlobalProvider';
import { extractConfiguration } from '../../utils/data';

export interface ICourseCard {
  course: CourseBaseInfo & Partial<CourseBaseInfoEngagement>
  titleClassName?: string
  subtitleClassName?: string
  detailsClassName?: string
  onClick(): void
  myLearnn?: boolean
  myLearnnId?: string
}

export const CourseCard: FC<ICourseCard> = ({ course, onClick, myLearnn, myLearnnId }) => {
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const blocked = !configuration?.premiumVisible && course.type.slug === 'webinar'

  return (
    <CourseCardDS
      onClick={onClick}
      title={course.shortTitle ?? course.title}
      coverImage={course.coverImage}
      companyLogo={course.companyLogo ?? undefined}
      subtitle={`${course.type.title}${course.duration ? ` • ${course.duration}` : ''}`}
      buttons={
        myLearnn ? (
          <MyLearnnButton
            itemType='course'
            itemId={course?.id ?? ''}
            myLearnnId={myLearnnId}
            className={style.myLearnnButton}
          />
        ) : undefined
      }
      pro={blocked}
      progressPercentage={course.progress}
    />
  );
};
