import { IFrame } from '../components/IFrame/IFrame'
import { AppLayout } from '../layouts/AppLayout/AppLayout'

export const DashboardB2BScreen = () => {
  return (
    <AppLayout fullwidth hideTitle disableMargin>
      <IFrame url='https://learnn.com/int/dashboard-b2b/' />
    </AppLayout>
  )
}
