import { Observable, retry } from 'rxjs'
import { useEffect, useState } from 'react'
import * as O from 'fp-ts/Option'
import { useQuery } from 'react-query'
import { QueryKey } from 'react-query/types/core/types'
type IProps<TResult> = {
  observableFn$: () => Promise<Observable<TResult>>
  queryKey: QueryKey
}
export type IHookResponse<TResult, TError> = {
  data: O.Option<TResult>
  isCompleted: boolean
  error: O.Option<TError>
}
export const useReactive = <TResult, TError>({
  observableFn$,
  queryKey,
}: IProps<TResult>): IHookResponse<TResult, TError> => {
  const [data, setData] = useState<O.Option<TResult>>(O.none)
  const [error, setError] = useState<O.Option<TError>>(O.none)
  const [isCompleted, setCompleted] = useState(false)
  const { data: observable$ } = useQuery<Observable<TResult>>(queryKey, observableFn$, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })

  useEffect(() => {
    if (!observable$) return

    const subscription = observable$.pipe(retry(3)).subscribe({
      next: (value: TResult) => {
        setData(O.some(value))
      },
      error: error => {
        console.error('HOOK ERROR', error)
        setError(O.some(error))
      },
      complete: () => {
        setCompleted(true)
      },
    })

    return () => subscription.unsubscribe()
  }, [observable$])
  return {
    data: data,
    error: error,
    isCompleted: isCompleted,
  }
}
