import {
  CourseLessonsEngagement,
  Module,
  ModuleEngagement,
} from '@learnn/sdk/src/api/course'
import { FC, useMemo, useRef } from 'react'
import useAnalytics from '../../../../../analytics/useAnalytics'
import { ModuleGroup } from './ModuleGroup'

type ModulesProps = {
  course: CourseLessonsEngagement
  currentLessonId: string
  currentModuleId: string
  userId: string
  onLessonCLick: (id: string) => void
}
export const Modules: FC<ModulesProps> = ({
  course,
  currentLessonId,
  currentModuleId,
  onLessonCLick,
}) => {
  const highlightModuleRef = useRef<null | HTMLDivElement>(null)
  const { logExpandCourseModule } = useAnalytics()

  const modulesMap = useMemo(
    () =>
      course?.modules?.map((module: Module & Partial<ModuleEngagement>, i) => {
        return (
          <div
            ref={module.id === currentModuleId ? highlightModuleRef : null}
            key={`module-course-${module.id}`}
            onClick={() => {
              if (!module.last_viewed) {
                logExpandCourseModule(module.id, module.title)
              }
            }}>
            <ModuleGroup
              currentLessonId={currentLessonId}
              module={module}
              courseId={course.id}
              lastItem={i === course?.modules.length - 1}
              initVisible={currentModuleId === module.id}
              onLessonCLick={onLessonCLick}
            />
          </div>
        )
      }),
    [course?.modules],
  )

  return <>{modulesMap}</>
}
