import { useRef, useState, useContext, useEffect } from 'react'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'
import { useBodyClass } from '../../utils/hooks'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import styles from './courseScreen.module.css'
import QueryString from 'query-string'
import { useQuery } from 'react-query'
import { matchQuery } from '../../utils/matchQuery'
import { useParams, useLocation } from 'react-router-dom'
import { OverviewSection } from './components/OverviewSection'
import { LessonsSection } from './components/LessonsSection'
import { NotesSection } from './components/NotesSection'
import { VerifiedSection } from './components/VerifiedSection'
import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import { getCourseBaseInfo } from '../../controllers/Course'
import { Header, Loader, Tabs } from './components/Header'
import { Container } from '@learnn/designn'
import ErrorScreen from '../Error'
import { ResourcesSection } from './components/ResourcesSection'
import { DiscussionSection } from './components/DiscussionSection'
import { extractConfiguration } from '../../utils/data'
import { useAutoplay } from './hooks/useAutoplay'

type LocationState = {
  selectedTab?: Tabs
}

type UrlParams = {
  showMenu?: string
  autoplay?: string
}

const extractShowMenu = (urlParams: UrlParams) => (urlParams?.showMenu ? true : false)
const extractAutoplay = (urlParams: UrlParams) => (urlParams?.autoplay ? true : false)

export const CourseScreen = () => {
  useBodyClass('app')
  const { id } = useParams<{ id: string }>()
  const urlParams = useRef(QueryString.parse(location.search))
  const { state } = useLocation<LocationState>()
  const openedTab = state?.selectedTab ?? 'overview'
  const showSide = extractShowMenu(urlParams.current)
  const autoplay = extractAutoplay(urlParams.current)
  const courseQuery = useQuery(['course', id], () => getCourseBaseInfo(id))
  const [selectedTab, setSelectedTab] = useState<Tabs>(openedTab)
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const quizAvailability = configuration?.quizAvailability ?? {}
  useAutoplay({ autoplay, courseId: id })

  const renderSections = (selectedTab: Tabs, course: CourseBaseInfoEngagement) => {
    const verifiedEnabled = !!quizAvailability[course.id]
    switch (selectedTab) {
      case 'overview':
        return (
          <OverviewSection
            courseId={id}
            onVerifyClick={() => setSelectedTab('verified')}
            onShowVerifiedClick={() => setSelectedTab('verified')}
            verifiedEnabled={verifiedEnabled}
          />
        )
      case 'modules':
        return <LessonsSection courseId={id} />
      case 'notes':
        return <NotesSection courseId={id} />
      case 'verified':
        return <VerifiedSection course={course} />
      case 'resources':
        return <ResourcesSection course={course} />
      case 'discussion':
        return <DiscussionSection course={course} />
    }
  }

  useEffect(() => {
    setSelectedTab('overview')
  }, [id])

  return (
    <AppLayout hideMobileBackToMenuButton hideTitle hideSecondaryMenu={!showSide} fullwidth disableMargin fillScreen>
      <>
        {matchQuery(courseQuery, {
          loading: () => <Loader />,
          error: () => (
            <Container>
              <ErrorScreen />
            </Container>
          ),
          success: course => (
            <>
              <Header
                selectedTab={selectedTab}
                course={course}
                onSelectTab={setSelectedTab}
                verifiedEnabled={!!quizAvailability[course.id]}
              />
              <div className={styles.container}>{renderSections(selectedTab, course)}</div>
            </>
          ),
        })}
      </>
    </AppLayout>
  )
}
