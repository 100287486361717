import { extractThumbUrlFromImage, COURSE_COMPLETED_THRESHOLD, normalizeGraphqlResponse, isLessonCompleted, isCourseCompletedByPercentage, } from '../utils';
import { gql } from '@apollo/client';
export const toProgress = (p) => {
    return p
        .filter(l => l.course && l.course.progress < COURSE_COMPLETED_THRESHOLD)
        .map(lesson => ({
        id: lesson.id,
        title: lesson.title,
        duration: lesson.duration,
        coverImage: lesson.course.coverImage,
        course: {
            id: lesson.course.id,
            title: lesson.course.title,
            profession: lesson.course.profession,
            author: lesson.course.author,
            category: lesson.course.category,
            coverImage: lesson.course.coverImage,
            imageUrl: lesson.course.coverImage,
            progress: lesson.course.progress,
            duration: lesson.course.duration,
            type: lesson.course.type,
        },
        progress: lesson.progress ?? 0,
    }));
};
export const toLastCourseprogress = (p) => {
    if (p.length) {
        const lesson = p[0];
        return lesson.course && lesson.course.progress < COURSE_COMPLETED_THRESHOLD
            ? {
                id: lesson.id,
                title: lesson.title,
                duration: lesson.duration,
                coverImage: lesson.course.coverImage,
                course: {
                    id: lesson.course.id,
                    title: lesson.course.title,
                    profession: lesson.course.profession,
                    author: lesson.course.author,
                    category: lesson.course.category,
                    coverImage: lesson.course.coverImage,
                    imageUrl: lesson.course.coverImage,
                    progress: lesson.course.progress,
                },
                progress: lesson.progress ?? 0,
            }
            : null;
    }
    return null;
};
export const filterCompletedLessons = (lesson) => !lesson.progress || (lesson.progress && !isLessonCompleted(lesson.progress));
export const filterCompletedCourse = (course) => !course.progress || (course.progress && !isCourseCompletedByPercentage(course.progress));
export const getQuizCourse = (client) => async (courseId) => {
    const toCourse = (course) => ({
        ...course,
        coverImage: extractThumbUrlFromImage(course?.coverImage, 'large') ?? '',
    });
    const courseResult = await client
        .query({
        query: COURSE_PREVIEW_BY_KEY,
        variables: { courseId },
    })
        .then(normalizeGraphqlResponse);
    const course = courseResult.data?.course;
    if (!course) {
        throw new Error(`course ${courseId} not found`);
    }
    return toCourse(course);
};
export const COMING_CONTENTS = gql `
  query ComingContents {
    comingContents(
      sort: "releaseDate:asc"
      pagination: { pageSize: 3 }
      filters: { hide: { eq: false }, type: { eq: "in_arrivo" } }
    ) {
      data {
        id
        attributes {
          title
          shortTitle
          type
          description
          releaseDate
          coverImage {
            data {
              attributes {
                formats
                urlCdn
              }
            }
          }
          author {
            data {
              attributes {
                firstName
                lastName
              }
            }
          }
          categories {
            data {
              attributes {
                title
              }
            }
          }
          contentUrl
        }
      }
    }
  }
`;
export const COMING_JOBS = gql `
  query ComingJobs {
    comingContents(
      sort: "createdAt:desc"
      pagination: { pageSize: 3 }
      filters: { hide: { eq: false }, type: { eq: "jobs" } }
    ) {
      data {
        id
        attributes {
          title
          shortTitle
          type
          description
          releaseDate
          coverImage {
            data {
              attributes {
                formats
                urlCdn
              }
            }
          }
          author {
            data {
              attributes {
                firstName
                lastName
              }
            }
          }
          categories {
            data {
              attributes {
                title
              }
            }
          }
          contentUrl
        }
      }
    }
  }
`;
const COURSE_PREVIEW_BY_KEY = gql `
  query courseByKey($courseId: ID!) {
    course(id: $courseId) {
      data {
        id
        attributes {
          title
          description

          coverImage {
            data {
              attributes {
                formats
                urlCdn
                url
              }
            }
          }

          categories {
            data {
              id
              attributes {
                title
              }
            }
          }
        }
      }
    }
  }
`;
