import { useContext, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Formik } from 'formik'
import style from './resetPasswordScreen.module.css'
import InputPassword from '../../components/InputPassword'

import cx from 'classnames'
import * as Yup from 'yup'
import Container from 'react-bootstrap/Container'

import Loader from 'react-loader-spinner'
import initApi from '../../controllers/api'
import { useBodyClass } from '../../utils/hooks'
import { GlobalProviderContext } from '../../components/GlobalProvider'

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'La password deve contenere almeno 8 caratteri, una lettera maiuscola, una minuscola e un numero.',
    )
    .required('Inserisci una password'),
  changepassword: Yup.string().when('password', {
    is: val => !!(val && val.length > 0),
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'Entrambe le password devono essere uguali')
      .required('Inserisci la password'),
  }),
})

export const ResetPassword = ({
  code,
  theme = 'light',
}: {
  code: string
  theme: 'light' | 'dark'
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId ?? ''

  if (theme === 'dark') {
    useBodyClass('app')
  }

  const handleForm = async (password: string, setSubmitting: any) => {
    setLoading(true)
    try {
      const api = await initApi

      if (code) {
        await api.account.updatePassword(userId, password)
      } else {
        await api.account.updatePassword(userId, password)
      }

      setError('')
      setSubmitted(true)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setSubmitting(false)
      setLoading(false)
      setError(
        'Si è verificato un errore durante la reimpostazione della password, riprova o contattaci in live chat',
      )
    }
  }

  if (loading) {
    return (
      <div className={!code ? style.loaderWrapDark : style.loaderWrap}>
        <Loader type='TailSpin' color={!code ? 'white' : 'black'} height={60} width={60} />
      </div>
    )
  }

  return (
    <>
      <Container className={style.container}>
        <Row className='justify-content-center'>
          <Col xs={12} md={6}>
            {!submitted ? (
              <>
                <h1 className={!code ? cx([style.title, style.loggedIn]) : style.title}>
                  Reimposta la tua password.
                </h1>
                <p className={!code ? cx([style.subtitle, style.loggedIn]) : style.subtitle}>
                  Inserisci qui sotto la tua nuova password.
                </p>
              </>
            ) : (
              <>
                <h1 className={!code ? cx([style.title, style.loggedIn]) : style.title}>
                  Password reimpostata.
                </h1>
                <p className={!code ? cx([style.subtitle, style.loggedIn]) : style.subtitle}>
                  La tua password è stata correttamente resettata.
                </p>
              </>
            )}
          </Col>
        </Row>
        <Row className='justify-content-center'>
          {!submitted ? (
            <Col xs={11} md={5} lg={4}>
              <Formik
                initialValues={{
                  password: '',
                  changepassword: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  validationSchema
                    .validate(values)
                    .then(function () {
                      handleForm(values.password, actions.setSubmitting)
                    })
                    .catch(function () {
                      actions.setSubmitting(false)
                      actions.setErrors({ password: 'Errore' })
                    })
                }}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                      <InputPassword
                        name='password'
                        placeholder='Nuova password...'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      <div className={style.errorText}>
                        {errors.password && touched.password && errors.password}
                      </div>
                      <input
                        className={cx(['form-control', style.inputField, 'mt-3', 'input-field'])}
                        type='password'
                        name='changepassword'
                        placeholder='Conferma password...'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.changepassword}
                      />
                      <div className={style.errorText}>
                        {errors.changepassword && touched.changepassword && errors.changepassword}
                      </div>
                    </div>
                    {error ? (
                      <div className={cx([style.errorText, 'mt-2'])} role='alert'>
                        {error}
                      </div>
                    ) : null}
                    <button
                      className={cx(['btn', 'mt-4', style.submitButton])}
                      type='submit'
                      disabled={isSubmitting}>
                      REIMPOSTA PASSWORD
                    </button>
                  </form>
                )}
              </Formik>
            </Col>
          ) : null}
        </Row>
      </Container>
    </>
  )
}
