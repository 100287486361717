import api from './api'
import { Observable, map } from 'rxjs'
import { CourseBaseInfoEngagement, LessonEngagement } from '@learnn/sdk/src/api/course'
import { SearchLesson } from '@learnn/sdk/src/api/smart-search'
import { Path } from '@learnn/sdk/src/api/paths/types'
import { ProgressiveSearchResult } from '@learnn/sdk/src/api/smart-search/progressiveSearch'

export type ProgressiveSearchContent = {
  all: CourseBaseInfoEngagement[]
  courses: CourseBaseInfoEngagement[]
  webinars: CourseBaseInfoEngagement[]
  lessons: (LessonEngagement & SearchLesson)[]
  paths: Path[]
}
export const progressiveSearch =
  (keyword: string) => async (): Promise<Observable<ProgressiveSearchContent>> => {
    const { smartSearch } = await api

    keyword = keyword.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83E[\uDD10-\uDDFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF])/g,
      '',
    )

    return smartSearch.progressiveSearch(keyword).pipe(
      map((data: ProgressiveSearchResult) => {
        return {
          all: data.courses,
          courses: data.courses.filter(i => i.type.slug === 'course'),
          webinars: data.courses.filter(i => i.type.slug === 'webinar'),
          lessons: data.lessons,
          paths: data.paths,
        }
      }),
    )
  }
