import { FC } from 'react'
import { ComingContent } from '../../screens/ComingSoonScreen/types'
import style from './comingCard.module.css'
import bellIcon from '../../assets/images/icons/bell.png'
import { getTextDateWithTime } from '@learnn/sdk/dist/api/utils'
import { CourseCard as CourseCardDS } from '@learnn/designn'

export interface IComingCard {
  course: ComingContent
  onClick?: Function
  containerClass?: string
  showDate?: boolean
}

export const ComingCard: FC<IComingCard> = ({ course: comingContent, onClick }) => {
  return (
    <div className={style.box}>
      <CourseCardDS
        onClick={() => {
          if (onClick) {
            onClick('coming_soon', comingContent.contentUrl)
          }
        }}
        subtitle={
          comingContent.releaseDateString
            ? getTextDateWithTime(comingContent.releaseDateString, 'long')
            : 'In arrivo su Learnn'
        }
        title={comingContent.shortTitle ?? comingContent.title ?? ''}
        coverImage={comingContent.coverImageUrl ?? ''}
        buttons={<img src={bellIcon} className={style.bell} />}
        hideProgressBar
      />
    </div>
  )
}
