import * as TE from 'fp-ts/TaskEither';
import { gql } from '@apollo/client';
import { pathDtoConverter } from './types';
import { DEFAULT_WALL_LIMIT, normalizeGraphqlResponse } from '../utils';
import { PATH_GRAPH } from './getPath';
export const getPaths = ({ client }) => () => {
    return TE.tryCatch(() => (async () => {
        const result = await client
            .query({
            query: GET_PATHS,
        })
            .then(normalizeGraphqlResponse);
        const paths = result.data?.paths;
        if (!paths) {
            throw new Error(`Paths not found`);
        }
        return paths.reduce((a, i) => {
            try {
                const path = pathDtoConverter(i);
                return [...a, path];
            }
            catch (e) {
                console.error(e);
                return a;
            }
        }, []);
    })(), r => {
        return new Error(String(r));
    });
};
const GET_PATHS = gql `
  query getPaths {
    paths(pagination: {pageSize: ${DEFAULT_WALL_LIMIT}}) {
      data {
        ${PATH_GRAPH}
      }
    }
  }
`;
