import { ResetPassword } from './ResetPassword'
import Footer from '../../components/Footer'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Header from '../../components/Header'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'
import { LiveChat } from '../../components/LiveChat'

interface RouteParams {
  code: string
}

interface MockComponent extends RouteComponentProps<RouteParams> {}

export const ResetPasswordScreen: React.FC<MockComponent> = props => {
  //@ts-ignore //TODO
  const code = props.match.params.code
  const isLocalhost =
    window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

  let manyChatScript
  if (!isLocalhost) {
    manyChatScript = <LiveChat />
  }

  return !code ? (
    <AppLayout>
      <ResetPassword code={code} theme='dark' />
    </AppLayout>
  ) : (
    <>
      {manyChatScript}
      <Header type='light' />
      <ResetPassword code={code} theme='light' />
      <Footer type='light' />
    </>
  )
}
