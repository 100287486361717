import { SocialProviders } from '@learnn/sdk/src/api/auth'
import { socialLogin } from '../../controllers/User'
import style from './socialLoginButtons.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import appleLogo from '../../assets/images/icons/apple_logo.svg'
import googleLogo from '../../assets/images/icons/google_logo.svg'
import { Text, useTheme } from '@learnn/designn'

type SocialLoginButtonsProps = {
  type: ButtonVariant
  next_step?: string
}

type ButtonVariant = 'login' | 'register'

const SOCIAL_REDIRECT_URL = '/social-redirect/'

export const SocialLoginButtons = ({ type, next_step }: SocialLoginButtonsProps) => {
  const handleSocialLogin = (social: SocialProviders) => async () => {
    const ssoUrl = await socialLogin(
      social,
      encodeURI(
        location.origin +
          SOCIAL_REDIRECT_URL +
          `?social=${social}${next_step ? `&next_step=${encodeURIComponent(next_step)}` : ''}`,
      ),
    )
    window.location.replace(ssoUrl)
  }

  return (
    <>
      <GoogleButton onClick={handleSocialLogin('google')} type={type} />
      <AppleButton onClick={handleSocialLogin('apple')} type={type} />
    </>
  )
}

export const GoogleButton = ({ onClick, type }: { onClick: () => void; type: ButtonVariant }) => {
  const { spacing } = useTheme()
  return (
    <div className={style.container} style={{ marginBottom: '1rem' }}>
      <div className={style.socailLoginContainer} onClick={onClick}>
        <img src={googleLogo} className={style.socialLogo} />
        <Text
          variant='bodySm'
          fontWeight='regular'
          width={{ _: '60%', small: '50%' }}
          ml={spacing.space_3}
          alignment='center'>
          <span>{type === 'login' ? 'Accedi' : 'Continua'} con Google</span>
        </Text>
      </div>
    </div>
  )
}

export const AppleButton = ({ onClick, type }: { onClick: () => void; type: ButtonVariant }) => {
  const { spacing } = useTheme()
  return (
    <div className={style.container} style={{ marginBottom: '1rem' }}>
      <div className={style.socailLoginContainer} onClick={onClick}>
        <img src={appleLogo} className={style.socialLogo} />
        <Text
          variant='bodySm'
          fontWeight='regular'
          width={{ _: '60%', small: '50%' }}
          ml={spacing.space_3}
          alignment='center'>
          <span>{type === 'login' ? 'Accedi' : 'Continua'} con Apple</span>
        </Text>
      </div>
    </div>
  )
}

export const EmailButton = ({ onClick, type }: { onClick: () => void; type: ButtonVariant }) => {
  const { spacing } = useTheme()
  return (
    <div className={style.container}>
      <div className={style.socailLoginContainer} onClick={onClick} data-test-id="login-email_button">
        <FontAwesomeIcon icon={faEnvelope} className={style.socialLogo} />
        <Text
          variant='bodySm'
          fontWeight='regular'
          width={{ _: '60%', small: '50%' }}
          ml={spacing.space_3}
          alignment='center'>
          <span>{type === 'login' ? 'Accedi' : 'Continua'} con Email</span>
        </Text>
      </div>
    </div>
  )
}
