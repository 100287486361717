"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.courseSummary = void 0;
const LESSON_COMPLETED_THRESHOLD = 50;
const COURSE_COMPLETED_THRESHOLD = 80;
const courseSummary = (course, lessonId) => {
    const concat = (x, y) => x.concat(y);
    const flatMap = (f, xs) => xs.map(f).reduce((x, y) => concat(x, y), []);
    const currentModule = course.modules.find(m => m.lessons.find(l => l.id === lessonId));
    const currentLesson = currentModule.lessons.find(l => l.id === lessonId);
    const lesson_type = currentLesson.contentType === 'text' ||
        (currentLesson.description && !currentLesson.videoUrl && !currentLesson.audioUrl)
        ? 'text'
        : 'video';
    const lessons = flatMap((m) => m.lessons.map((l) => ({
        id: l.id,
        moduleId: m.id,
        progress: l.progress,
        completed: l.progress >= LESSON_COMPLETED_THRESHOLD,
        started: l.progress > 0,
    })), course.modules);
    const moduleLessons = lessons.filter(l => l.moduleId === currentModule?.id);
    const lesson_progress = currentLesson?.progress ? currentLesson?.progress : 0;
    const total_lessons = course.modules.map(m => m.lessons.length).reduce((a, b) => a + b, 0);
    const module_started = moduleLessons.filter(l => l.started).length > 0;
    const module_completed = moduleLessons.filter(l => l.completed).length === moduleLessons.length;
    const last_of_module = moduleLessons[moduleLessons.length - 1].id === lessonId &&
        moduleLessons.filter(l => l.id !== lessonId && !l.completed).length === 0;
    const last_of_course = lessons[lessons.length - 1].id === lessonId &&
        lessons.filter(l => l.id !== lessonId && !l.completed).length === 0;
    const course_progress = Math.round((lessons.filter(l => l.completed).length / lessons.length) * 100);
    const module_progress = Math.round((moduleLessons.filter(l => l.completed).length / moduleLessons.length) * 100);
    const authors = typeof course.author !== 'undefined'
        ? `${course.author.firstName} ${course.author.lastName}`
        : '';
    const summary = {
        lesson_id: lessonId,
        authors,
        course_id: course.id,
        course_title: course.title,
        categories_ids: course.categoriesIds.join(','),
        module_id: currentModule.id,
        module_title: currentModule.title,
        lesson_title: currentLesson.title,
        total_modules: course.modules.length,
        total_lessons,
        lesson_progress,
        lesson_started: lesson_progress > 0,
        lesson_completed: lesson_progress > LESSON_COMPLETED_THRESHOLD,
        lesson_type,
        course_started: lessons.filter(l => l.started).length > 0,
        course_completed: course_progress >= COURSE_COMPLETED_THRESHOLD,
        course_progress,
        last_of_course,
        module_started,
        module_completed,
        last_of_module,
        module_progress,
    };
    return summary;
};
exports.courseSummary = courseSummary;
