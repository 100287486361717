import React from "react";
import { createGtmAnalyticsProvider } from "./providers";
import { analytics, voidAnalytics, consoleAnalytics } from "@learnn/analytics";
import { GlobalProviderContext, IConfiguration } from "../components/GlobalProvider/context";
import { configureGTMWebapp } from "./gtm";
import { Provider } from "@learnn/analytics/src/providers";

//TODO: custom select lesson in course screen including course data
//TODO: extract content type and id from url (when possible)
//TODO: track scroll seconds stay view
//TODO: track single content
//TODO: track trailer
export const getAnalyticsProviderFactory = (configuration: IConfiguration) => {
  if (configuration.trackingMode === "console") {
    return consoleAnalytics;
  }
  if (configuration.trackingMode === "gtm") {
    const { sendToGTM } = configureGTMWebapp();
    return createGtmAnalyticsProvider(sendToGTM);
  }

  return voidAnalytics;
};

export const useAnalytics = () => {
  const globalProviderContext = React.useContext(GlobalProviderContext);
  const provider = globalProviderContext?.analyticsProvider ?? voidAnalytics();
  const route = { name: window.location.pathname };
  return createAnalytics(provider, route.name);
};
export const createAnalytics = (provider: Provider, route_name?: string) => {
  return analytics(provider, route_name ?? window.location.pathname);
};
export default useAnalytics;
