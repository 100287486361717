import { IFrame } from '../../components/IFrame/IFrame'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

export const ToolsScreen = () => {
  return (
    <AppLayout fullwidth disableMargin hideTitle hideSecondaryMenu>
      <IFrame url='https://learnn.com/tools/' fullHeight />
    </AppLayout>
  )
}
