import { useContext, useEffect } from "react";
import cx from "classnames";
import { useQuery } from "react-query";
import { CardsGrid } from "../../components/CardsGrid/CardsGrid";
import { JobCard } from "../../components/JobCard";
import { getJobs } from "../../controllers/Job";
import Loader from "react-loader-spinner";
import { ErrorMessage } from "../../components/ErrorMessage";
import { useBodyClass } from "../../utils/hooks";
import style from "./jobsScreen.module.css";
import useAnalytics from "../../analytics/useAnalytics";
import { GlobalProviderContext } from "../../components/GlobalProvider";
import { extractConfiguration } from "../../utils/data"; //@ts-ignore
import env from "../../env.json";
import { ComingContent } from '../ComingSoonScreen/types';
import { AppLayout } from "../../layouts/AppLayout/AppLayout";

export const JobsScreen = () => {
  const { isLoading, error, data } = useQuery(["jobs"], getJobs);
  const globalContext = useContext(GlobalProviderContext);
  const configuration = extractConfiguration(globalContext);
  const blocked = !configuration?.premiumVisible;
  const { logSelectContent } = useAnalytics();

  useBodyClass("app");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <AppLayout>
      {error ? (
        <div className={style.block}>
          <ErrorMessage />
        </div>
      ) : isLoading ? (
        <div className={style.containerLoading}>
          <Loader type="ThreeDots" color="#FFFFFF" height={50} width={50} />
        </div>
      ) : (
        <div className={style.block}>
          <div className={style.featured}>
            {blocked ? (
              <>
                <CardsGrid type="courses">
                  {data?.slice(0, 3).map((item: ComingContent) => {
                    return <JobCard onClick={logSelectContent} key={`job-${item?.id}`} job={item} />;
                  })}
                </CardsGrid>
                <div className={style.blockContainer}>
                  <button className={cx([style.moreButton, blocked && env.PRO_JOB_CLASS])}>Mostra tutti i Jobs</button>
                </div>
              </>
            ) : (
              <CardsGrid type="jobs">
                {data?.map((item: ComingContent) => {
                  return <JobCard onClick={logSelectContent} key={`job-${item?.id}`} job={item} />;
                })}
              </CardsGrid>
            )}
          </div>
        </div>
      )}
    </AppLayout>
  );
};
