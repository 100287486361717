import { SectionValue } from '@learnn/sdk/src/api/dynamic-sections/types'
import { FeaturedCards } from './FeaturedCards'
import { LastCourse } from './LastCourse'
import { LastProgress } from './LastProgress'
import { SearchBar } from '../SearchBar'
import { PathCourses } from './PathCourses'
import { ContentCarousel } from './ContentCarousel'
import { ChartCarousel } from './ChartCarousel'
import { PathSavedContents } from './PathSavedContents'
import { useContext } from 'react'
import { GlobalProviderContext } from '../GlobalProvider'
import { useHistory } from 'react-router-dom'
import { QuizCarousel } from './QuizCarousel'
import { PathCompletedCourses } from './PathCompletedCourses'
import { PathsCarousel } from './PathsCarousel'
import { LessonsCarousel } from './LessonsCarousel'
import { Profile } from './Profile'
import { CoursesBySegmentCarousel } from './CoursesBySegmentCarousel'

type RenderSectionProps = {
  section: SectionValue
  onSearch: (keyword: string) => unknown
}
export const RenderSection = ({ section, onSearch }: RenderSectionProps) => {
  const globalContext = useContext(GlobalProviderContext)
  const history = useHistory()

  switch (section.__typename) {
    case 'ComponentSectionStaticFeatureContent':
      return <FeaturedCards data={section.data} />
    case 'ComponentSectionDynamicLastProgresses':
      return <LastProgress data={section.data} />
    case 'ComponentSectionDynamicSearch':
      return (
        <SearchBar
          bordered
          onSearch={onSearch}
          filters={globalContext?.configuration.searchCategories}
          data={section.data}
        />
      )
    case 'ComponentSectionStaticCourses':
      return <PathCourses data={section.data} myLearnn />
    case 'ComponentSectionStaticLessons':
      return <LessonsCarousel data={section.data} myLearnn />
    case 'ComponentSectionDynamicComingContent':
      return (
        <ContentCarousel
          data={section.data}
          onShowAll={type =>
            type === 'in_arrivo' ? history.push(`/in-arrivo`) : history.push(`/jobs`)
          }
        />
      )
    case 'ComponentSectionDynamicMostViewed':
      return <ChartCarousel data={section.data} />
    case 'ComponentSectionDynamicMostUseful':
      return <ChartCarousel data={section.data} />
    case 'ComponentSectionDynamicTrendingNow':
      return <ChartCarousel data={section.data} />
    case 'ComponentSectionDynamicTopPicksForYou':
      return <ChartCarousel data={section.data} />
    case 'ComponentSectionDynamicSavedContents':
      return <PathSavedContents data={section.data} onShowAll={() => history.push(`/salvati`)} />
    case 'ComponentSectionDynamicQuiz':
      return (
        <QuizCarousel
          data={section.data}
          onShowAll={type => history.push(type === 'verified' ? '/verified' : `/sfide`)}
        />
      )
    case 'ComponentSectionDynamicLastSeenCourse':
      return <LastCourse data={section.data} />
    case 'ComponentSectionDynamicCompletedCourses':
      return (
        <PathCompletedCourses data={section.data} onShowAll={() => history.push(`/completati`)} />
      )
    case 'ComponentSectionStaticPaths':
      return <PathsCarousel data={section.data} />
    case 'ComponentSectionDynamicLatestContent':
      return <PathCourses data={section.data} myLearnn />
    case 'ComponentSectionDynamicDynamicLessons':
      return <LessonsCarousel data={section.data} myLearnn />
    case 'ComponentSectionDynamicProfile':
      return <Profile data={section.data} />
    case 'ComponentSectionDynamicMostViewedBySegment':
      return <CoursesBySegmentCarousel data={section.data} />

    default:
      return <></>
  }
}
