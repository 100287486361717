import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import { useHistory } from 'react-router-dom'
import styles from './header.module.css'
import { Title, useTheme, Box, HorizontalStack } from '@learnn/designn'
import { MainCta } from '../MainCta'
import { ReactComponent as BackIcon } from '../../../../assets/images/icons/back_arrow.svg'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import env from '../../../../env.json'
import { useContext, useEffect } from 'react'
import { GlobalProviderContext } from '../../../../components/GlobalProvider'
import { extractConfiguration } from '../../../../utils/data'
import cx from 'classnames'
import { useQuery } from 'react-query'
import { getCourseVerifiedStatus } from '../../../../controllers/Quiz'
import { matchQuery } from '../../../../utils/matchQuery'
import useAnalytics from '../../../../analytics/useAnalytics'

export type HeaderProps = {
  course: CourseBaseInfoEngagement
  selectedTab: Tabs
  onSelectTab: Function
  verifiedEnabled: boolean
}

export type Tabs = 'overview' | 'modules' | 'notes' | 'verified' | 'resources' | 'discussion'

export const Header = ({ course, selectedTab, onSelectTab, verifiedEnabled }: HeaderProps) => {
  const { spacing, colors } = useTheme()
  const history = useHistory()
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const { logCommunityOpenCourse } = useAnalytics()
  const userId = globalContext?.userId ?? ''

  const verifiedStatusQuery = useQuery(['verifiedAvailability', course.id], () =>
    getCourseVerifiedStatus(userId, course.id),
  )

  const isSelected = (tab: string) => tab === selectedTab

  useEffect(() => {
    if (course.progress > 0) {
      onSelectTab('modules')
    }
  }, [])

  return (
    <div className={styles.header}>
      <div className={styles.backIconContainer}>
        <BackIcon
          className={styles.backIcon}
          onClick={() => {
            if (history.action === 'PUSH') history.goBack()
            else history.push('/')
          }}
        />
      </div>
      <div className={styles.menuContainer}>
        <Box onClick={() => onSelectTab('overview')} className={styles.menuItem}>
          <Title variant='headingSm' fontWeight={isSelected('overview') ? 'black' : 'semibold'}>
            Overview
          </Title>
        </Box>
        <Box
          ml={spacing.space_10}
          onClick={() => onSelectTab('modules')}
          className={styles.menuItem}>
          <Title variant='headingSm' fontWeight={isSelected('modules') ? 'black' : 'semibold'}>
            Moduli
          </Title>
        </Box>
        <Box ml={spacing.space_10} onClick={() => onSelectTab('notes')} className={styles.menuItem}>
          <Title variant='headingSm' fontWeight={isSelected('notes') ? 'black' : 'semibold'}>
            Note
          </Title>
        </Box>
        {course.resources && (
          <HorizontalStack
            ml={spacing.space_10}
            onClick={() => configuration?.premiumVisible && onSelectTab('resources')}
            className={cx([
              styles.menuItem,
              !configuration?.premiumVisible && env.PRO_RESOURCES_CLASS,
            ])}
            alignItems='center'>
            {!configuration?.premiumVisible ? (
              <FontAwesomeIcon icon={faLock} size='sm' style={{ color: colors.text.dimmed }} />
            ) : null}
            <Title
              variant='headingSm'
              fontWeight={isSelected('resources') ? 'black' : 'semibold'}
              ml={spacing.space_1}>
              Risorse
            </Title>
          </HorizontalStack>
        )}
        {course.circlePostUrl && (
          <Box
            ml={spacing.space_10}
            onClick={() => {
              onSelectTab('discussion')
              logCommunityOpenCourse(course.id)
            }}
            className={styles.menuItem}>
            <Title variant='headingSm' fontWeight={isSelected('discussion') ? 'black' : 'semibold'}>
              Discussione
            </Title>
          </Box>
        )}
        {matchQuery(verifiedStatusQuery, {
          loading: () => <></>,
          error: () => <></>,
          success: data => {
            return (
              <HorizontalStack
                ml={spacing.space_10}
                onClick={() => onSelectTab('verified')}
                className={styles.menuItem}
                alignItems='center'>
                {course.progress < env.QUIZ_ALLOW_THRESHOLD && data.status !== 'passed' ? (
                  <FontAwesomeIcon icon={faLock} size='sm' style={{ color: colors.text.dimmed }} />
                ) : null}
                <Title
                  variant='headingSm'
                  fontWeight={isSelected('verified') ? 'black' : 'semibold'}
                  ml={spacing.space_1}>
                  Verifica
                </Title>
              </HorizontalStack>
            )
          },
        })}
      </div>
      <div className={styles.ctaContainer}>
        {course.type.slug === 'course' && verifiedEnabled ? (
          matchQuery(verifiedStatusQuery, {
            loading: () => <></>,
            error: () => <></>,
            success: verifiedState => {
              return (
                <MainCta
                  size='md'
                  course={course}
                  verify={{
                    verifiedState,
                    onShowVerifiedClick: () => onSelectTab('verified'),
                    onVerifyCourseClick: () => onSelectTab('verified'),
                  }}
                />
              )
            },
          })
        ) : (
          <MainCta size='md' course={course} />
        )}
      </div>
    </div>
  )
}

export const Loader = () => {
  const { spacing } = useTheme()
  const SkeletonComponent = ({ br }: { br?: number }) => (
    <Skeleton height='100%' borderRadius={br ?? 15} />
  )

  return (
    <div className={styles.header}>
      <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
        <div style={{ width: '200px', height: '35px' }} className={styles.hideMobile}>
          <SkeletonComponent />
        </div>
        <HorizontalStack>
          <Box style={{ height: '30px', width: '80px' }} ml={spacing.space_8}>
            <SkeletonComponent />
          </Box>
          <Box style={{ height: '30px', width: '80px' }} ml={spacing.space_8}>
            <SkeletonComponent />
          </Box>
          <Box style={{ height: '30px', width: '80px' }} ml={spacing.space_8}>
            <SkeletonComponent />
          </Box>
          <Box style={{ height: '30px', width: '80px' }} ml={spacing.space_8}>
            <SkeletonComponent />
          </Box>
        </HorizontalStack>
        <div style={{ width: '200px', height: '40px' }} className={styles.hideMobile}>
          <SkeletonComponent />
        </div>
      </SkeletonTheme>
    </div>
  )
}
