import styles from './styles/pathItem.module.css'
import { ResponsiveImage } from '../../../components/ResponsiveImage'
import { FC } from 'react'
import cx from 'classnames'
import { CourseWithProgress } from '../../../controllers/Paths'
import { CircularProgress } from '../../../components/CircularProgress'
import { ReactComponent as StartLogo } from '../../../assets/images/icons/play_circle_white.svg'
import { ReactComponent as CompletedLogo } from '../../../assets/images/icons/passed_quiz.svg'
import { ReactComponent as VerifiedLogo } from '../../../assets/images/icons/verified.svg'
import { ReactComponent as ToVerifyLogo } from '../../../assets/images/icons/verified_outline.svg'

import { faPlay, faRepeat } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Course } from '@learnn/sdk/src/api/paths/types'
import { Button } from '@learnn/designn'

export type PathItemProps = {
  containerStyle?: string
  item: Course & Partial<CourseWithProgress>
  onClick: () => unknown
}
export const PathItem: FC<PathItemProps> = ({ item, containerStyle, onClick }) => {
  const { type, image, title, author } = item

  const details = [
    item.durationString,
    `${item.lessonsCount} ${item.lessonsCount === 1 ? 'Lezione' : 'Lezioni'}`,
  ]

  const renderStatus = () => {
    switch (item.status) {
      case 'to_start':
        return <StartLogo className={styles.statusIcon} />
      case 'to_finish':
        return (
          <CircularProgress
            progress={Math.round(item.progress ?? 0)}
            size={48}
            labelSize='12px'
            trackWidth={2}
            indicatorWidth={3.5}
          />
        )
      case 'competed':
        return <CompletedLogo className={styles.statusIcon} />
      case 'to_verify':
        return <ToVerifyLogo className={styles.statusIcon} />
      case 'verified':
        return <VerifiedLogo className={styles.statusIcon} />
      default:
        return (
          <div className={styles.circularLoader}>
            <span></span>
          </div>
        )
    }
  }

  const renderCallToAction = () => {
    switch (item.status) {
      default:
      case 'to_start':
        return (
          <Button
            icon={<FontAwesomeIcon icon={faPlay} />}
            variant='primary'
            size='lg'
            iconPosition='right'
            label='Inizia'
          />
        )
      case 'to_finish':
        return (
          <Button
            icon={<FontAwesomeIcon icon={faPlay} />}
            variant='primary'
            size='lg'
            iconPosition='right'
            label='Continua'
          />
        )
      case 'competed':
        return (
          <Button
            icon={<FontAwesomeIcon icon={faRepeat} />}
            variant='primary'
            size='lg'
            iconPosition='right'
            label='Riguarda'
          />
        )
      case 'to_verify':
        return (
          <Button
            icon={<ToVerifyLogo className={styles.ctaIcon} />}
            variant='primary'
            size='lg'
            iconPosition='right'
            label='Verifica'
          />
        )
      case 'verified':
        return (
          <Button
            icon={<ToVerifyLogo className={styles.ctaIcon} />}
            variant='primary'
            size='lg'
            iconPosition='right'
            label='Vedi verifica'
          />
        )
    }
  }

  return (
    <div
      className={cx([styles.container, containerStyle, item.verified ? styles.verified : ''])}
      onClick={onClick}>
      <div className={styles.status}>{renderStatus()}</div>
      <div className={styles.imageContainer}>
        <ResponsiveImage wrapperClassName={styles.image} src={image} />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.type}>{type.title}</div>
          <div className={styles.title}>{title}</div>
          <div className={styles.author}>{author}</div>
        </div>
        <div className={styles.details}>
          {details.map((detail, i) => (
            <div className={styles.detailItem} key={i}>
              {detail}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.overlay}>{renderCallToAction()}</div>
    </div>
  )
}
