import style from './modalComing.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'
import { ResponsiveImage } from '../ResponsiveImage'
import * as O from 'fp-ts/Option'
import ICalendarLink from 'react-icalendar-link'
import { getTextDateWithYear } from '@learnn/sdk/dist/api/utils'
import moment from 'moment'
import { ComingContent } from '../../screens/ComingSoonScreen/types'
import { useShare } from '../SharePopup/useShare'
import { useEffect, useState } from 'react'
import { ICalEvent } from 'react-icalendar-link/dist/utils'
import { HorizontalStack, Markdown, Button, CircularButton, useTheme } from '@learnn/designn'
import { ReactComponent as ClockIcon } from '../../assets/images/icons/clock.svg'

export type ModalComingPropsOpen = {
  status: 'open'
  comingContent: ComingContent
  onClose: Function
}
export type ModalComingPropsClosed = {
  status: 'closed'
}
export type ModalComingProps = ModalComingPropsOpen | ModalComingPropsClosed
export const ModalComing = (props: ModalComingProps) => {
  const [event, setEvent] = useState<O.Option<ICalEvent>>(O.none)
  const { share } = useShare()
  const [calendarButtonStatus, setCalendarButtonStatus] = useState<'downloaded' | 'downloadable'>(
    'downloadable',
  )
  const { spacing } = useTheme()

  useEffect(() => {
    switch (props.status) {
      case 'closed':
        break
      case 'open':
        if (
          props.comingContent.releaseDate &&
          moment.utc(props.comingContent.releaseDateString).toISOString()
        ) {
          setEvent(
            O.some({
              startTime: moment.utc(props.comingContent.releaseDateString).toISOString(),
              endTime: moment
                .utc(props.comingContent.releaseDateString)
                .add(1, 'hour')
                .toISOString(),
              title: props.comingContent.title
                ? `${props.comingContent.title} su Learnn`
                : 'Evento Learnn',
              description: props.comingContent.description ?
                props.comingContent.description
                  .replaceAll('\n', '\\n')
                : undefined,
              url: 'https://my.learnn.com/live',
            }),
          )
        }
    }
  }, [props])

  const getTime = (date: string | undefined | null) => {
    if (date) return `- ${moment(new Date(date)).format('HH:mm')}`
    return ''
  }

  switch (props.status) {
    case 'closed':
      return <></>
    case 'open': {
      const { onClose, comingContent } = props
      return (
        <div
          className={style.popupContainer}
          onClick={event => {
            event.preventDefault()
            onClose()
          }}>
          <div className={style.content}>
            <FontAwesomeIcon icon={faTimes} className={style.closeIcon} onClick={() => onClose()} />
            <div
              className={cx([style.box])}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}>
              <div className={comingContent.contentUrl ? style.clickableCard : style.card}>
                <div className={style.imageContainer}>
                  <ResponsiveImage
                    wrapperClassName={style.borderedImage}
                    src={comingContent.coverImageUrl ?? ''}
                  />
                </div>
                <div className={style.cardBody}>
                  <div className={style.cardHeader}>
                    <p className={style.title}>{comingContent.title}</p>
                  </div>
                  {comingContent.releaseDate ? (
                    <div className={style.detailBadge}>
                      <ClockIcon className={style.deailIcon} />
                      <div>{`${comingContent.releaseDate} ${getTime(
                        comingContent.releaseDateString,
                      )}`}</div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {comingContent.description ? (
                    <Markdown>{comingContent.description}</Markdown>
                  ) : (
                    <></>
                  )}
                  <HorizontalStack alignItems='center'>
                    {O.isSome(event) && (
                      <a
                        id='test'
                        className={style.extraButtonContainer}
                        onClick={() => {
                          onClose()
                          setCalendarButtonStatus('downloaded')
                        }}>
                        <ICalendarLink
                          className={style.noHover}
                          event={event.value}
                          filename={`learnn-event-${comingContent.id}.ics`}>
                          <Button
                            icon={<FontAwesomeIcon icon={faCalendarAlt} className={style.extraButtonIcon} />}
                            iconPosition='left'
                            size='md'
                            label={(() => {
                              switch (calendarButtonStatus) {
                                case 'downloadable':
                                  return 'Promemoria'
                                case 'downloaded':
                                  return 'Aggiunto'
                              }
                            })()}
                            onPress={() => { }}
                            variant='primary'
                            width={{_: '100%', small: '200px'}}
                          />
                        </ICalendarLink>
                      </a>
                    )}
                    {comingContent.contentUrl && (
                      <CircularButton
                        variant='secondary'
                        size='md'
                        onPress={() => {
                          comingContent.contentUrl &&
                            share({
                              type: 'comingContent',
                              url: comingContent.contentUrl,
                              body: `Il ${getTextDateWithYear(
                                comingContent.releaseDateString,
                              )} alle ore ${moment(comingContent.releaseDateString).format(
                                'HH:mm',
                              )} è in arrivo su Learnn "${comingContent.title
                                }" \n \nSe vuoi partecipare, trovi tutto a questo link: ${comingContent.contentUrl
                                }`,
                            })
                        }}
                        icon={<FontAwesomeIcon icon={faPaperPlane} className={style.circularButtonIcon} />} 
                        ml={spacing.space_6}
                      />
                    )}
                  </HorizontalStack>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}
