import { Integrations } from './Integrations'
import Footer from '../../components/Footer'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Header from '../../components/Header'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

interface RouteParams {
  code: string
}

interface MockComponent extends RouteComponentProps<RouteParams> {}

export const IntegrationsScreen: React.FC<MockComponent> = props => {
  //@ts-ignore //TODO
  const code = props.match.params.code

  return !code ? (
    <AppLayout>
      <Integrations code={code} theme='dark' />
    </AppLayout>
  ) : (
    <>
      <Header type='light' />
      <Integrations code={code} theme='light' />
      <Footer type='light' />
    </>
  )
}
