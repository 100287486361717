import style from './styles/menu.module.css'
import cx from 'classnames'
import { faBrain, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../assets/images/logo.png'
import { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { MenuProviderContext } from './menuContext'
import { Accordion } from './Accordion'
import { useAssistantPopup } from '../AssistantPopup/useAssistantPopup'
import env from '../../env.json'

export type NavigationProps = {
  hideSecondaryMenu?: boolean
}

export type SecondaryMenuGroup = {
  type: 'group'
  label: string
}
export type SecondaryMenuItem = {
  type: 'item'
  label: string
  url: string
  external?: boolean
  disabled?: boolean
}
export type SecondaryMenu = SecondaryMenuGroup | SecondaryMenuItem
export type MacroMenuItem = {
  label?: string
  position: 'top' | 'bottom'
  disabled?: boolean
  icon: JSX.Element
  subs?: SecondaryMenu[]
  url?: string
}
export type Menu = MacroMenuItem[]

export const Navigation: React.FC<NavigationProps> = ({ hideSecondaryMenu }) => {
  const history = useHistory()
  const { state: sidebarState, dispatch } = useContext(MenuProviderContext)
  const [activeMacroIndex, activeSecondaryIndex] = sidebarState.menuPosition
  const activeMenuSubs = sidebarState.menu.find((_item, index) => index === activeMacroIndex)?.subs
  const { open } = useAssistantPopup()

  return (
    <>
      <div className={style.sideBarContainer}>
        <div className={style.macroMenuContainer}>
          <div className={style.topGroup} id='macro-menu-top-group'>
            {sidebarState.menu.map(
              (item, i) =>
                item.position === 'top' && (
                  <div
                    id={`${item.label}-link`}
                    onClick={() => {
                      history.push(
                        item.url
                          ? item.url
                          : item.subs && item.subs.length > 0
                            ? item.subs[0].type === 'item'
                              ? item.subs[0].url
                              : ''
                            : '',
                      )
                    }}
                    key={i}
                    className={cx([
                      style.macroMenuItem,
                      activeMacroIndex === i ? style.active : null,
                      item.disabled && style.hidden,
                    ])}>
                    {item.icon}
                    {item.label && <p className={style.macroMenuItemLabel}>{item.label}</p>}
                  </div>
                ),
            )}
            {
              env.AI_ENABLED && (
                <div
                  onClick={() => {
                    open()
                  }}
                  className={style.macroMenuItem}>
                  <FontAwesomeIcon icon={faBrain} className={style.macroMenuItemIcon} />
                  <p className={style.macroMenuItemLabel}>AI</p>
                </div>
              )
            }
          </div>
          <div id='macro-menu-bottom-group'>
            {sidebarState.menu.map(
              (item, i) =>
                item.position === 'bottom' && (
                  <div
                    id={`${item.label}-link`}
                    onClick={() => {
                      history.push(
                        item.url
                          ? item.url
                          : item.subs && item.subs.length > 0
                            ? item.subs[0].type === 'item'
                              ? item.subs[0].url
                              : ''
                            : '',
                      )
                    }}
                    key={i}
                    className={cx([
                      style.macroMenuItem,
                      activeMacroIndex === i ? style.active : null,
                      item.disabled && style.hidden,
                    ])}>
                    {item.icon}
                    {item.label && <p className={style.macroMenuItemLabel}>{item.label}</p>}
                  </div>
                ),
            )}
          </div>
        </div>
        {!hideSecondaryMenu && activeMenuSubs && activeMenuSubs?.length > 0 && (
          <div className={style.secondaryMenuContainer}>
            <div className={style.logoContainer}>
              <img className={style.logo} src={logo} alt='Learnn' />
            </div>
            <div className={style.topGroup} id='secondary-menu-top-group'>
              {activeMenuSubs &&
                activeMenuSubs.map((item, i) =>
                  item.type === 'item' ? (
                    item.external ? (
                      <a
                        href={item.url}
                        key={i}
                        className={cx([
                          style.secondaryMenuItem,
                          activeSecondaryIndex === i ? style.active : null,
                          item.disabled && style.hidden,
                        ])}
                        target='_blank'
                        rel='noreferrer'>
                        {item.label}
                      </a>
                    ) : (
                      <Link
                        to={item.url}
                        key={i}
                        className={cx([
                          style.secondaryMenuItem,
                          activeSecondaryIndex === i ? style.active : null,
                          item.disabled && style.hidden,
                        ])}>
                        {item.label}
                      </Link>
                    )
                  ) : (
                    <div
                      key={i}
                      className={cx([
                        style.secondaryMenuGroup,
                        activeSecondaryIndex === i ? style.active : null,
                      ])}>
                      {item.label}
                    </div>
                  ),
                )}
            </div>
            <div id='secondary-menu-bottom-group' className={style.bottomGroup}></div>
          </div>
        )}
      </div>
      <div
        className={cx([
          style.mobileContainer,
          sidebarState.mobileMenuStatus !== 'closed' && style.mobileContainerOpened,
        ])}>
        <div className={style.mobileHeader}>
          <FontAwesomeIcon
            icon={faXmark}
            className={style.mobileHeaderIcon}
            onClick={() => dispatch({ mobileMenuStatus: 'closed' })}
          />
        </div>
        <div className={style.mobileInner}>
          {sidebarState.menu.map((item, i) => (
            <Accordion
              key={`manu-${i}`}
              item={item}
              isLast={i === sidebarState.menu.length - 1}
              onItemPress={() => {
                dispatch({
                  menuPosition: [i, 0],
                  mobileMenuStatus: 'closed',
                })
                history.push(
                  item.url
                    ? item.url
                    : item.subs && item.subs.length > 0
                      ? item.subs[0].type === 'item'
                        ? item.subs[0].url
                        : ''
                      : '',
                )
              }}
              onSubPress={() =>
                dispatch({
                  menuPosition: [activeMacroIndex, i],
                  mobileMenuStatus: 'closed',
                })
              }
            />
          ))}
        </div>
      </div>
    </>
  )
}
