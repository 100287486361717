import { motion } from 'framer-motion'
import { FC, MouseEventHandler, useContext } from 'react'
import cx from 'classnames'
import style from './lessonItem.module.css'
import { Lesson, LessonEngagement } from '@learnn/sdk/src/api/course'
import { GlobalProviderContext } from '../../../../../components/GlobalProvider'
import { extractConfiguration } from '../../../../../utils/data'
import { ProgressBar } from '../../../../../components/ProgressBar'
import { MyLearnnButton } from '../../../../../components/MyLearnnButton'
import { HorizontalStack, useTheme } from '@learnn/designn'
import env from '../../../../../env.json'

type LessonItem = {
  lesson: Partial<LessonEngagement> & Lesson
  onClick?(): void
  onContextMenu?: MouseEventHandler<HTMLHeadingElement>
  continueWatching: boolean
}

export const LessonItem: FC<LessonItem> = ({
  lesson,
  onClick = () => { },
  onContextMenu,
  continueWatching,
}) => {
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)

  const { colors } = useTheme()

  const blocked = !configuration?.premiumVisible && !lesson.free

  const handleClick = () => {
    if (!blocked && onClick) {
      onClick()
    }
  }

  return (
    <motion.div
      className={style.lessonContainer}
      animate={
        continueWatching && {
          // @ts-ignore backgroundColor doesn't exist in animate props type
          backgroundColor: [colors.item_hover, colors.item_active],
        }
      }
      transition={{
        delay: 1.1,
        duration: 0.6,
      }}>
      <motion.div className={style.container}>
        <div
          className={cx([style.innerContainer, blocked && env.PRO_LESSON_CLASS])}
          onClick={handleClick}
          onContextMenu={onContextMenu}>
          <div className={style.info}>
            <div className={style.topDetails}>
              <HorizontalStack alignItems='center'>
                <h4 className={style.title}>{lesson.title}</h4>
                {blocked ? <div className={style.proBadge}>Pro</div> : <></>}
              </HorizontalStack>
              {lesson.tags?.length && lesson.tags.length > 0 ? (
                <HorizontalStack alignItems='center'>
                  {lesson.tags.slice(0, 2).map(tag => (
                    <div key={`tag-${tag.id}`} className={style.tagBadge} style={{borderWidth: continueWatching ? 1 : 0}}>
                      {tag.name}
                    </div>
                  ))}
                </HorizontalStack>
              ) : (
                <></>
              )}
            </div>
            <div className={style.details}>
              <p className={style.duration}>{lesson.duration}</p>
              <ProgressBar width='10em' fillPercentage={lesson.progress} />
            </div>
          </div>
        </div>
      </motion.div>
      <div className={style.buttonsContainer}>
        {configuration?.features?.mylearnn !== false && (
          <MyLearnnButton
            itemType='lesson'
            itemId={lesson.id}
            myLearnnId={lesson.myLearnnId}
            containerClass={style.circularButton}
            className={cx([style.myLearnn])}
          />
        )}
      </div>
    </motion.div>
  )
}
