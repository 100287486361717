import {
  ComponentSectionStaticPaths,
  DynamicSectionsError,
  DynamicSectionsValue,
} from '@learnn/sdk/src/api/dynamic-sections/types'
import * as F from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import { PathListItem } from '../../screens/PathScreen/components/PathListItem'
import { useHistory } from 'react-router-dom'
import { CustomCarousel, CustomCarouselLoading } from '../Carousel/CustomCarousel'

export interface IPathsCarouselProps {
  data: DynamicSectionsValue<DynamicSectionsError, ComponentSectionStaticPaths>
}

export const PathsCarousel = ({ data }: IPathsCarouselProps) => {
  const history = useHistory()

  const error = (error: DynamicSectionsError) => {
    console.error(error)
    return <></>
  }

  const render = (section: ComponentSectionStaticPaths) => {
    return (
      <CustomCarousel 
        title={section.title}
        data={section.paths}
        carouselKind='course'
        renderSlide={path => {
          return (
            <PathListItem path={path} onClick={() => history.push(`/paths/${path.slug}`)} />
          )
        }}
      />
    );
  }

  return F.pipe(
    data,
    O.fold(CustomCarouselLoading, data => {
      return F.pipe(data, E.fold(error, render))
    }),
  )
}
