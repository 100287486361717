import { Provider } from './Provider'

type SendFunction = (data: { [key: string]: any }) => Promise<void>

export default function createGtmAnalyticsProvider(sendToGTM: SendFunction) {
  return () => {
    const logEvent = async (name: string, params: { [key: string]: any }) => {
      return await sendToGTM({ ...{ event: 'ga4_' + name }, ...params })
    }
    const logSelectContent = async (contentType: string, itemId: string) =>
      logEvent('screen_view', { contentType, itemId })
    const logLogin = async (method?: string) => logEvent('login', { method: method ?? 'strapi' })
    const logScreenView = async (screenClass: string, screenName: string) =>
      logEvent('screen_view', { screenClass, screenName })
    const setUserProperties = async (properties: { [key: string]: any }) => sendToGTM(properties)
    const setUserProperty = async (name: string, value: any) => {
      let o = {}
      //@ts-ignore //TODO
      o[name] = value
      setUserProperties(o)
    }
    const setUserId = async (id: string) => setUserProperty('userId', id)
    return {
      logLogin,
      logSelectContent,
      logEvent,
      logScreenView,
      setUserId,
      setUserProperties,
      setUserProperty,
      name: 'gtm',
    } as Provider
  }
}
