import { EditProfile, Profile } from '@learnn/sdk/src/api/profile'
import api from './api'
import * as O from 'fp-ts/Option'

export const getUserProfile = async (userId: string): Promise<O.Option<Profile>> => {
  const { profile } = await api

  try {
    return O.some(await profile.get(userId))
  } catch (error: unknown) {
    if (error instanceof Error && error.message === 'Not Found') {
      return O.none
    }
    throw error
  }
}

export const updateProfile = async (newProfile: EditProfile) => {
  const { profile } = await api

  return profile.editProfile(newProfile)
}

export const uploadProfileImage = async (file: File) => {
  const { profile } = await api

  return profile.uploadProfileImage(file)
}

export const getGoals = async () => {
  const { profile } = await api

  return profile.getGoals()
}

export const getCompanyRoles = async () => {
  const { profile } = await api

  return profile.getCompanyRoles()
}

export const getInterests = async () => {
  const { course } = await api

  const courseCategories = await course.getCourseCategories()

  return courseCategories.map(c => ({ id: String(c.id), label: c.title }))
}

export const getSegments = async () => {
  const { profile } = await api

  return profile.getSegments()
}
