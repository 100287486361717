import style from './comingSoonScreen.module.css'
import { CardsGrid } from '../../components/CardsGrid/CardsGrid'
import { ComingContent } from './types'
import { ComingCard } from '../../components/ComingCard'
import { ModalComing, ModalComingProps } from '../../components/ModalComing/ModalComing'
import { useQuery } from 'react-query'
import { getComingContents } from '../../controllers/ComingSoon'
import useAnalytics from '../../analytics/useAnalytics'
import { useState } from 'react'
import { ErrorMessage } from '../../components/ErrorMessage'
import Loader from 'react-loader-spinner'

export const ComingSoon = () => {
  const { isLoading, error, data } = useQuery(['comingContents'], getComingContents)
  const { logSelectContent } = useAnalytics()
  const [modalComingProps, setModalComingProps] = useState<ModalComingProps>({ status: 'closed' })

  return (
    <>
      {error ? (
        <div className={style.block}>
          <ErrorMessage />
        </div>
      ) : isLoading ? (
        <div className={style.containerLoading}>
          <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50} />
        </div>
      ) : (
        <div className={style.block}>
          <div className={style.featured}>
            <CardsGrid type='courses'>
              {data?.map((item: ComingContent) => {
                return (
                  <ComingCard
                    onClick={async () => {
                      setModalComingProps({
                        status: 'open',
                        comingContent: item,
                        onClose: () => setModalComingProps({ status: 'closed' }),
                      })
                      await logSelectContent(item.type, item.id)
                    }}
                    key={`coming-${item?.id}`}
                    course={item}
                  />
                )
              })}
            </CardsGrid>
          </div>
          <ModalComing {...modalComingProps} />
        </div>
      )}
    </>
  )
}
