import React, { useState } from 'react'
import { useAsyncMemo } from '../../utils/hooks'
import { magicLinkLogin, generateTokenFromAutologinToken } from '../../controllers/User'
import { useHistory, useLocation } from 'react-router-dom'
import api from '../../controllers/api'

export interface IAutologinProps {
  children: React.ReactChild | React.ReactChild[]
}

export const Autologin = ({ children }: IAutologinProps) => {
  const history = useHistory()
  const location = useLocation()
  const [ready, setReady] = useState(false)

  useAsyncMemo(
    async () => {
      const queryParams = new URLSearchParams(location.search)
      const authorizationCode = queryParams.get('code')
      const autologin = queryParams.get('autologin')
      const social = queryParams.get('social')
      const next_step = queryParams.get('next_step')
      const appUrl = queryParams.get('appUrl')

      try {
        if (authorizationCode) {
          if (social) {
            await magicLinkLogin(
              `${window.location.origin}${window.location.pathname}?social=${social}${
                next_step ? `&next_step=${encodeURIComponent(next_step)}` : ''
              }`,
              authorizationCode,
            )
          } else {
            await magicLinkLogin(
              `${window.location.origin}${window.location.pathname}${
                appUrl ? `?appUrl=${encodeURIComponent(appUrl)}` : ''
              }`,
              authorizationCode,
            )
          }

          queryParams.delete('code')
          queryParams.delete('session_state')

          history.replace({
            search: queryParams.toString(),
          })
        } else if (autologin) {
          await generateTokenFromAutologinToken(autologin)
          const { auth } = await api;
          await auth.createKeycloakSession()
          queryParams.delete('autologin')
          history.replace({
            search: queryParams.toString(),
          })
        }
        setReady(true)
      } catch (e) {
        setReady(true)
        console.error('Autologin error', e)
      }
    },
    [],
    null,
  )

  if (ready) return children
  else return null
}
