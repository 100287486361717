import { useEffect, useState } from 'react'
import { IFrame } from '../components/IFrame/IFrame'
import { AppLayout } from '../layouts/AppLayout/AppLayout'
import { LoadingIndicator } from '../components/LoadingIndicator'
import api from '../controllers/api'
import useAnalytics from '../analytics/useAnalytics'

export const CommunityScreen = () => {
  const [ready, setReady] = useState(false)
  const { logCommunityOpen } = useAnalytics()

  useEffect(() => {
    const load = async () => {
      const { auth } = await api
      await auth.createKeycloakSession()
      setReady(true)
      logCommunityOpen()
    }
    load()
  }, [])

  return (
    <AppLayout hideSecondaryMenu fillcontent hideTitle>
      {ready ? (
        <IFrame
          url='https://community.learnn.com/c/84693?iframe=true&automatic_login=true'
          fullHeight
        />
      ) : (
        <LoadingIndicator />
      )}
    </AppLayout>
  )
}
