import styles from './styles/sidebar.module.css'
import { FC, useContext } from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Path } from '@learnn/sdk/dist/api/paths/types'
import { faBookBookmark, faClock } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as VerifiedLogo } from '../../../assets/images/icons/verified.svg'
import { GlobalProviderContext } from '../../../components/GlobalProvider'
import { extractConfiguration } from '../../../utils/data'
import { Markdown } from '@learnn/designn'

export type SidebarProps = {
  containerStyle?: string
  path: Path
}
export const Sidebar: FC<SidebarProps> = ({ containerStyle, path }) => {
  const { title, summary, description } = path
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)

  const totalHoursDuration = path.courses.reduce((prev: number, i) => prev + i.durationHour, 0)
  const quizAvailability = configuration?.quizAvailability ?? {}
  const verifiedCount = path.courses.filter(c => c.type.slug === 'course').filter(course => !!quizAvailability[course.id]).length
  const details = [
    {
      icon: <FontAwesomeIcon icon={faBookBookmark} className={styles.detailIcon} size='sm' />,
      value: `${path.courses.length} Corsi e Webinar`,
    },
    {
      icon: <FontAwesomeIcon icon={faClock} className={styles.detailIcon} size='sm' />,
      value: `${totalHoursDuration} Ore totali`,
    },
    {
      icon: <VerifiedLogo className={styles.detailIcon} />,
      value: `${verifiedCount} ${verifiedCount === 1 ? 'Verifica' : 'Verifiche'}`,
    },
  ]

  return (
    <div className={cx([styles.container, containerStyle])}>
      <div className={styles.subtitle}>Percorso</div>
      <div className={styles.title}>{title}</div>
      <div className={styles.summary}>{summary}</div>
      {description !== ' ' && (
        <>
          <div className={styles.subtitle}>Descrizione</div>
          <Markdown>{description}</Markdown>
        </>
      )}
      <div className={styles.subtitle}>Dettagli</div>
      <div className={styles.details}>
        {details.map(detail => (
          <div className={styles.detailItem}>
            {detail.icon}
            {detail.value}
          </div>
        ))}
      </div>
    </div>
  )
}
