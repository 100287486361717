import api from "./api";
import {DynamicSectionsState} from "@learnn/sdk/src/api/dynamic-sections/types";
import {Observable} from "rxjs";
import env from '../env.json'

export type ComingContent = {
  id: string;
  title: string | null;
  type: "in_arrivo" | "jobs";
  description: string | null;
  releaseDate: string | null;
  releaseDateObject?: DateObject;
  coverImageUrl: string | null;
  author: { firstName: string | null; lastName: string | null };
  categories: { title: string | null }[] | null;
  contentUrl: string | null;
};

type DateObject = {
  day: string;
  month: string;
  yearh: string;
};


export const getCustomHome = (userId: string | undefined) => async (): Promise<Observable<DynamicSectionsState>> => {
  const {home} = await api;
  return home.getCustomHome(userId ?? "", {recommendedCourseHideThreshold: env.RECOMMENDED_COURSE_HIDE_THRESHOLD, client: 'web'});
};
