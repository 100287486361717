import cx from 'classnames'
import styles from './styles/playerSideContent.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { IVideoReadOnlyState } from '../useReactPlayer'
import { LessonBrowseTab } from './LessonBrowserTab'
import { NoteTab } from './NoteTab'
import { DescriptionTab } from './DescriptionTab'
import { TranscriptionTab } from './TranscriptionTab'
import { Vtt } from '@learnn/sdk/src/api/utils'
import { usePlayerSide } from './usePlayerSide'
import useAnalytics from '../../../analytics/useAnalytics'
import levetta from '../../../assets/images/icons/levetta.svg'
import arrow from '../../../assets/images/icons/arrow.svg'
import { useContext, useEffect } from 'react'
import { GlobalProviderContext } from '../../GlobalProvider'
import { CourseStatusCard } from '../../CourseStatusCard/CourseStatusCard'
import { getCourse, getCourseResources } from '../../../controllers/Course'
import { useQuery } from 'react-query'
import { matchQuery } from '../../../utils/matchQuery'
import { HorizontalStack, VerticalStack, useTheme } from '@learnn/designn'
import { LessonTags } from '@learnn/sdk/src/api/course'

export type PlayerSideContentProps = {
  initialSelectedTab?: number
  containerStyle?: string
  title: string
  lessonTags: LessonTags[]
  currentLessonId: string
  moduleId: string
  courseId: string
  lessonId: string
  state?: IVideoReadOnlyState
  viewTime: number
  lessonDescription?: string
  courseName: string
  subtitles: Vtt[]
  onBlockClick: (seconds: number) => void
}

export const PlayerSideContent = ({
  initialSelectedTab,
  containerStyle,
  currentLessonId,
  title,
  lessonTags,
  moduleId,
  lessonId,
  courseId,
  state,
  viewTime,
  lessonDescription,
  courseName,
  subtitles,
  onBlockClick,
}: PlayerSideContentProps) => {
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId ?? ''
  const courseQuery = useQuery(['courseLessons', courseId], () => getCourse(courseId))
  const resourcesQuery = useQuery(['courseResources', courseId], () => getCourseResources(courseId))
  const { state: playerSideState, toggle: togglePlayerSide, setSelectedTab } = usePlayerSide()
  const {
    logSidebarShowLessons,
    logSidebarShowNotes,
    logSidebarShowTranscipt,
    logSidebarShowResources,
    logSidebarToggle,
  } = useAnalytics()
  const { spacing } = useTheme()

  useEffect(() => {
    if (initialSelectedTab) {
      togglePlayerSide()
      logSidebarToggle(true, courseId, moduleId, lessonId)
      setSelectedTab(initialSelectedTab)
    }
  }, [initialSelectedTab])
  return (
    <>
      <div
        className={styles.toggleContainer}
        style={{
          backgroundImage: `url(${levetta}`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          backgroundSize: 'contain',
        }}
        onClick={() => {
          togglePlayerSide()
          logSidebarToggle(playerSideState.status === 'visible', courseId, moduleId, lessonId)
        }}>
        <img
          className={cx([styles.arrow, playerSideState.status === 'visible' ? styles.invert : ''])}
          src={arrow}
        />
      </div>
      <div
        className={cx([
          styles.sideContent,
          playerSideState.status === 'visible' ? styles.openLarge : '',
        ])}>
        <div className={cx([containerStyle, styles.container])}>
          <div className={styles.header} id='sidebar-header'>
            <div className={styles.courseTitle}>{courseName}</div>
            <div className={styles.title}>{title}</div>
            {lessonTags?.length && lessonTags.length > 0 ? (
              <HorizontalStack alignItems='center' mt={spacing.space_2}>
                {lessonTags.slice(0, 2).map(tag => (
                  <div key={`tag-${tag.id}`} className={styles.tagBadge}>
                    {tag.name}
                  </div>
                ))}
              </HorizontalStack>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.tabsContainer}>
            <Tabs
              className={styles.tabsContainerInner}
              selectedIndex={playerSideState.selectedTab}
              onSelect={index => setSelectedTab(index)}>
              <TabList id='tab-list'>
                <Tab onClick={() => logSidebarShowLessons(courseId, moduleId, lessonId)}>
                  <h3 className={styles.tabTitle} data-active={playerSideState.selectedTab === 0}>
                    Moduli
                  </h3>
                </Tab>
                <Tab onClick={() => logSidebarShowNotes(courseId, moduleId, lessonId)}>
                  <h3 className={styles.tabTitle} data-active={playerSideState.selectedTab === 1}>
                    Note
                  </h3>
                </Tab>
                <Tab
                  aria-hidden={subtitles === null || subtitles.length === 0}
                  onClick={() => logSidebarShowTranscipt(courseId, moduleId, lessonId)}>
                  <h3 className={styles.tabTitle} data-active={playerSideState.selectedTab === 2}>
                    Trascrizione
                  </h3>
                </Tab>
                {matchQuery(resourcesQuery, {
                  loading: () => <></>,
                  error: () => <></>,
                  success: resources =>
                    resources || lessonDescription ? (
                      <Tab onClick={() => logSidebarShowResources(courseId, moduleId, lessonId)}>
                        <h3
                          className={styles.tabTitle}
                          data-active={playerSideState.selectedTab === 3}>
                          Risorse
                        </h3>
                      </Tab>
                    ) : (
                      <></>
                    ),
                })}
              </TabList>
              <TabPanel
                className={styles.tabContainer}
                data-active={playerSideState.selectedTab === 0}>
                <div className={styles.tabContainerInner}>
                  <LessonBrowseTab
                    userId={userId}
                    currentLessonId={currentLessonId}
                    currentModuleId={moduleId}
                    courseId={courseId}
                    state={state}
                  />
                </div>
              </TabPanel>
              <TabPanel
                className={styles.tabContainer}
                data-active={playerSideState.selectedTab === 1}>
                <div className={styles.tabContainerInner}>
                  <NoteTab
                    courseId={courseId}
                    lessonId={lessonId}
                    moduleId={moduleId}
                    viewTime={viewTime}
                  />
                </div>
              </TabPanel>
              <TabPanel
                className={styles.tabContainer}
                data-active={playerSideState.selectedTab === 2}>
                {subtitles.length > 0 && (
                  <div className={styles.tabContainerInner}>
                    <TranscriptionTab
                      subtitles={subtitles}
                      viewTime={viewTime}
                      onBlockClick={onBlockClick}
                    />
                  </div>
                )}
              </TabPanel>

              <TabPanel
                className={styles.tabContainer}
                data-active={playerSideState.selectedTab === 3}>
                <div className={styles.tabContainerInner}>
                  <DescriptionTab courseId={courseId} description={lessonDescription} />
                </div>
              </TabPanel>
            </Tabs>
          </div>
          {matchQuery(courseQuery, {
            loading: () => <></>,
            error: () => <></>,
            success: course => (
              <VerticalStack p='20px'>
                <CourseStatusCard
                  variant='small'
                  userId={userId}
                  courseId={courseId}
                  courseProgressPercentage={Math.round(course.progress ?? 0)}
                />
              </VerticalStack>
            ),
          })}
        </div>
      </div>
    </>
  )
}

export const PlayerSideModal = (props: PlayerSideContentProps & { onDismiss: () => void }) => {
  const { onDismiss, ...childProps } = props
  return (
    <div
      className={styles.popupContainer}
      onClick={event => {
        event.preventDefault()
        onDismiss()
      }}>
      <div className={styles.content}>
        <FontAwesomeIcon icon={faTimes} className={styles.closeIcon} onClick={() => onDismiss()} />
        <div
          className={cx([styles.box])}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}>
          <PlayerSideContent {...childProps} />
        </div>
      </div>
    </div>
  )
}
