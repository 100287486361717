import { useContext } from 'react'
import { PlayerSideContext } from './playerSideContext'

export const usePlayerSide = () => {
  const { dispatch, state: prevState } = useContext(PlayerSideContext)

  return {
    show: () => {
      dispatch({
        ...prevState,
        status: 'visible',
      })
    },
    hide: () => {
      dispatch({
        ...prevState,
        status: 'hide',
      })
    },
    toggle: () => {
      dispatch({
        ...prevState,
        status: prevState.status === 'visible' ? 'hide' : 'visible',
        ...(prevState.status === 'visible' && { selectedTab: 0 }),
      })
    },
    setFocus: (focus: boolean) => {
      dispatch({
        ...prevState,
        playerSideFocus: focus,
      })
    },
    setSelectedTab: (index: number) => {
      dispatch({
        ...prevState,
        selectedTab: index,
      })
    },
    state: prevState,
  }
}
