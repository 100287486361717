import api from './api'
import {
  CourseBaseInfo,
  CourseBaseInfoEngagement,
  CourseCategory,
  CourseLessons,
  CourseLessonsEngagement,
  CourseType,
  Lesson,
  Module,
} from '@learnn/sdk/src/api/course'
import { Observable } from 'rxjs'
import auth from '../utils/auth'

export const getWall =
  ({ type, showAll = false }: { type: CourseType; category?: CourseCategory, showAll?: boolean }) =>
  async (): Promise<Observable<(CourseBaseInfo & Partial<CourseBaseInfoEngagement>)[]>> => {
    const { engagement, course } = await api
    return new Observable<(CourseBaseInfo & Partial<CourseBaseInfoEngagement>)[]>(subscriber => {
      new Promise(async () => {
        let result: CourseBaseInfo[]

        if (showAll) {
          result = await course.getAll()
        } else {
          result = await course.getList(type)
        }

        subscriber.next(result)

        const courseIds = result.map(c => c.id)
        const coursesEngagement = await engagement.getCourses(courseIds)
        const { coursesEngagement: coursesHash } = coursesEngagement

        const wall: CourseBaseInfoEngagement[] = result.map(c => ({
          ...c,
          progress: coursesHash && coursesHash[c.id] ? coursesHash[c.id].progress : 0,
          myLearnnId: coursesHash && coursesHash[c.id] ? coursesHash[c.id].myLearnnId : undefined,
        }))

        subscriber.next(wall)
      }).catch(e => subscriber.error(e))
    })
  }

export const getCourse = async (courseId: string): Promise<CourseLessonsEngagement> => {
  const { engagement, course } = await api

  try {
    const courseItem = await course.get(courseId)
    const courseEngagement = await engagement.getCourse(courseId)

    return applyCourseEngagement(courseItem, courseEngagement)
  } catch (e) {
    console.warn(e)
    throw e
  }
}

export const getProgressiveCourseModuleLessons =
  (courseId: string) =>
  async (): Promise<Observable<CourseLessons & Partial<CourseLessonsEngagement>>> => {
    const { engagement, course } = await api

    try {
      return new Observable<CourseLessons & Partial<CourseLessonsEngagement>>(subscriber => {
        course.get(courseId).then((courseLessons: CourseLessons) => {
          subscriber.next(courseLessons as CourseLessons & Partial<CourseLessonsEngagement>)

          engagement.getCourse(courseId).then((engagement: any) => {
            subscriber.next(applyCourseEngagement(courseLessons, engagement))
          })
        })
      })
    } catch (e) {
      console.warn(e)
      throw e
    }
  }

export const getCourseInfo = async (courseId: string): Promise<CourseLessons> => {
  const { course } = await api

  try {
    const courseItem = await course.get(courseId)
    return courseItem
  } catch (e) {
    console.warn(e)
    throw e
  }
}

export const getCourseBaseInfo = async (courseId: string): Promise<CourseBaseInfoEngagement> => {
  const { course, engagement } = await api
  const applyCourseEngagement = (c: CourseBaseInfo, engagement: any) => {
    return {
      ...c,
      progress: engagement.progress,
      lastProgress: engagement.lastProgress,
      lastViewedModule: engagement.lastViewModuleId,
      myLearnnId: engagement.myLearnnId,
      likeState: engagement.likeState,
    }
  }

  try {
    const courseItem = await course.getCourseBaseInfo(courseId)
    const courseEngagement = await engagement.getCourse(courseId)
    return applyCourseEngagement(courseItem, courseEngagement)
  } catch (e) {
    console.warn(e)
    throw e
  }
}

export const getCoursePreview = async (courseId: string) => {
  const { course } = await api

  try {
    const courseItem = await course.getPreview(courseId)

    return courseItem
  } catch (e) {
    console.warn(e)
    throw e
  }
}

const applyCourseEngagement = (c: CourseLessons, engagement: any): CourseLessonsEngagement => {
  const modules = c.modules.map(applyModuleEngagement(engagement))
  return {
    ...c,
    modules: modules,
    progress: engagement ? engagement.progress : 0,
    lastProgress: engagement ? engagement.lastProgress : undefined,
    lastViewedModule: engagement ? engagement.lastViewModuleId : undefined,
    myLearnnId: engagement ? engagement.myLearnnId : undefined,
    likeState: engagement ? engagement.likeState : undefined,
  }
}
const applyModuleEngagement = (engagement: any) => (m: Module) => {
  const { modules } = engagement

  return {
    ...m,
    module_started: modules && modules[m.id] ? modules[m.id].module_started : false,
    completed_lessons: modules && modules[m.id] ? modules[m.id].completed_lessons : 0,
    module_progress: modules && modules[m.id] ? modules[m.id].module_progress : 0,
    module_length: modules && modules[m.id] ? modules[m.id].module_length : 0,
    module_duration:
      modules && modules[m.id] ? calculateDuration(modules[m.id].module_duration) : '0 min',
    last_viewed: engagement && engagement.lastViewModuleId === m.id ? true : false,
    lessons: m.lessons.map(applyLessonEngagement(engagement.lessons)),
  }
}

const applyLessonEngagement = (engagement: any) => (l: Lesson) => ({
  ...l,
  progress: engagement && engagement[l.id] ? engagement[l.id].progress : 0,
  myLearnnId: engagement && engagement[l.id] ? engagement[l.id].myLearnnId : undefined,
  likeState: engagement && engagement[l.id] ? engagement[l.id].likeState : null,
})

const calculateDuration = (secs: number) => {
  if (!secs) {
    return ''
  }

  if (secs < 60) return `1 min`

  const m = Math.trunc(secs / 60)

  return `${m} min`
}

export const getCourseTrailer = async (courseId: string) => {
  try {
    const { course } = await api
    const trailer = await course.getTrailer(courseId)

    return trailer
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getCourseResources = async (courseId: string) => {
  try {
    const { course } = await api
    const resources = await course.getResources(courseId)
    return resources
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getCourseHighlight = async (courseId: string) => {
  try {
    const { course } = await api
    const highlight = await course.getHighlightVideo(courseId)

    return highlight
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const getCourseNotes = async (courseId: string) => {
  try {
    const { note } = await api
    const notes = await note.getCourseNotes(courseId)

    return notes
  } catch (error) {
    console.warn(error)
    throw error
  }
}

export const getCompletedCourses = async (): Promise<CourseBaseInfoEngagement[]> => {
  try {
    const { engagement } = await api
    const courses = await engagement.getCompletedCourses()

    return courses
  } catch (error) {
    console.warn(error)
    throw error
  }
}

export const getCourseCategories = async (): Promise<CourseCategory[]> => {
  try {
    const { course } = await api
    const categories = await course.getCourseCategories()

    return categories
  } catch (error) {
    console.warn(error)
    throw error
  }
}

export const getCompletedHideList = (): string[] => {
  let hideList = auth.getCourseCompletedWidgetHide() as string

  if (hideList) {
    return JSON.parse(hideList)
  } else {
    return []
  }
}
