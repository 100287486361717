import { FC } from 'react'
import { Path } from '@learnn/sdk/src/api/paths/types'
import { PathListItem } from '../../screens/PathScreen/components/PathListItem'
import { PathWithProgress } from '../../controllers/Paths'
import { CustomCarousel } from '../Carousel/CustomCarousel'


type PathsCarouselProps = {
  title: string
  paths: PathWithProgress[]
  onShowAll?: Function
  onPathClick: (path: Path) => unknown
}

export const PathsCarousel: FC<PathsCarouselProps> = ({ title, paths, onShowAll, onPathClick }) => {

  return (
    <CustomCarousel
      title={title}
      data={paths}
      carouselKind='course'
      onShowAll={onShowAll}
      renderSlide={path => {
        return (
          <PathListItem
            path={path}
            onClick={() => onPathClick(path)}
          />
        )
      }}
    />
  );

}