import { AppLayout } from '../../layouts/AppLayout/AppLayout'
import { useBodyClass } from '../../utils/hooks'
import styles from './pathScreen.module.css'
import { PathItem } from './components/PathItem'
import { Sidebar } from './components/Sidebar'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import { CourseStatus, CourseWithProgress, getPath } from '../../controllers/Paths'
import { ErrorMessage } from '../../components/ErrorMessage'
import { useHistory, useParams } from 'react-router-dom'
import { PathCertificateItem, PathStatus } from './components/PathCertificateItem'
import cx from 'classnames'
import { useContext, useState } from 'react'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { PathAttemptData } from '@learnn/sdk/src/api/quiz'
import { PathCertificateAchieved } from './components/PathCertificateAchieved'
import { useReactive } from '../../utils/useReactive'
import { Course } from '@learnn/sdk/src/api/paths/types'
import { extractConfiguration } from '../../utils/data'

export const PathScreen = () => {
  useBodyClass('app')
  const history = useHistory()
  const { slug } = useParams<{ slug: string }>()
  const globalContext = useContext(GlobalProviderContext)
  const configuration = extractConfiguration(globalContext)
  const quizAvailability = configuration?.quizAvailability ?? {}
  const userId = globalContext?.userId ?? ''
  const [receviedPathAttempt, setReceivedPathAttempt] = useState<PathAttemptData | null>()

  const { data, error } = useReactive({
    observableFn$: getPath(slug, userId),
    queryKey: ['path', slug, userId],
  })

  const getActionByStatus = (status: CourseStatus | undefined, courseId: string) =>
    status === 'to_verify' || status === 'verified'
      ? history.push(`/corso/${courseId}`, { selectedTab: 'verified' })
      : history.push(`/corso/${courseId}`)

  return pipe(
    error,
    O.fold(
      () =>
        pipe(
          data,
          O.fold(
            () => <>loading</>,
            data => {
              const getPathStatus = (): PathStatus => {
                const isEngagementLoading = data.courses
                  .reduce((arr: boolean[], course: Course & Partial<CourseWithProgress>) => {
                    if (course.status === undefined) return [...arr, true]
                    return [...arr, false]
                  }, [])
                  .every(el => el)
                const isPathCompleted = data.courses
                  .filter(course => !!quizAvailability[course.id])
                  .reduce((arr: boolean[], course: Course & Partial<CourseWithProgress>) => {
                    if (
                      (course.status &&
                        (course.status === 'competed' || course.status === 'verified')) ||
                      course.type.slug !== 'course'
                    )
                      return [...arr, true]
                    return [...arr, false]
                  }, [])
                  .every(el => el)

                return isEngagementLoading
                  ? 'loading'
                  : isPathCompleted
                  ? 'to_verify'
                  : 'in_progress'
              }
              return (
                <AppLayout backButton title={data.title} showLastProgress={false}>
                  <div className={styles.container}>
                    <div className={styles.modules}>
                      {data.courses.map((item: Course & Partial<CourseWithProgress>, i) => (
                        <PathItem
                          item={item}
                          containerStyle={cx([styles.moduleItem])}
                          key={i}
                          onClick={() => getActionByStatus(item.status, item.id)}
                        />
                      ))}
                      <PathCertificateItem
                        path={data}
                        userId={userId}
                        progress={data.progress}
                        status={getPathStatus()}
                        containerStyle={styles.moduleItem}
                        onCertificationReceived={attempt => {
                          setReceivedPathAttempt(attempt)
                        }}
                      />
                    </div>
                    <div className={styles.sidebar}>
                      <Sidebar path={data} />
                      <PathCertificateItem
                        path={data}
                        userId={userId}
                        progress={data.progress}
                        status={getPathStatus()}
                        containerStyle={styles.moduleItem}
                        onCertificationReceived={attempt => {
                          setReceivedPathAttempt(attempt)
                        }}
                        type='sidebar'
                      />
                    </div>
                  </div>

                  {receviedPathAttempt && (
                    <PathCertificateAchieved
                      attempt={receviedPathAttempt}
                      onSubmitClick={() => setReceivedPathAttempt(null)}
                      path={data}
                    />
                  )}
                </AppLayout>
              )
            },
          ),
        ),
      () => <ErrorMessage />,
    ),
  )
}
