import env from '../env.json'
import { API, LocalStorageWrapper } from '@learnn/sdk'
import auth from '../utils/auth'

const createClientInjectingJwt = async () => {
  await auth

  const token = auth.getToken() ? await String(auth.getToken()) : undefined

  const api = await API.initialize(
    {
      cache: {
        persistentStorage: new LocalStorageWrapper(window.localStorage),
      },
    },
    env.CMS_URL,
    env.RECOMMENDER_API_URL,
    env.QUIZ_API_URL,
    env.QUIZ_CONFIG_API_URL,
    env.ENGAGEMENT_URL,
    env.MEILI_SEARCH_API_URL,
    env.MEILI_SEARCH_API_KEY,
    token,
    env.QUIZ_API_KEY,
    env.QUIZ_CONFIG_API_KEY,
    env.ENGAGEMENT_GRAPH_URL,
    env.ENGAGEMENT_GRAPH_KEY,
    env.KEYCLOAK_REALM_URL,
    env.WEB_KEYCLOAK_CLIENT_ID,
    env.WEB_KEYCLOAK_CLIENT_SECRET,
    env.CMS_API_TOKEN,
    env.IDENTITY_API_URL,
    env.CHECKOUT_API_URL,
    env.PROFILE_API_URL,
    env.PROFILE_REST_API_URL,
    env.PROFILE_API_KEY,
    env.MY_API_URL,
    env.CMS_CONFIG_API_URL,
    env.ORAMA_COURSES_INDEX_ENDPOINT,
    env.ORAMA_COURSES_INDEX_PUBLIC_KEY,
    env.ORAMA_LESSONS_INDEX_ENDPOINT,
    env.ORAMA_LESSONS_INDEX_PUBLIC_KEY,
    env.ORAMA_PATHS_INDEX_ENDPOINT,
    env.ORAMA_PATHS_INDEX_PUBLIC_KEY,
  )

  return api
}

export const api = createClientInjectingJwt()
export default api
