import React, { FC, useState } from 'react'
import style from './searchBar.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faSearch } from '@fortawesome/free-solid-svg-icons'
import useAnalytics from '../../analytics/useAnalytics'
import { Box, HorizontalScroll, Title, VerticalStack, useTheme, TextInput } from '@learnn/designn'
import { ComponentSectionDynamicSearch, DynamicSectionsError, DynamicSectionsValue } from '@learnn/sdk/src/api/dynamic-sections/types'
import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'

export type SearchBarProps = {
  initialKeyword?: string
  bordered?: boolean
  onSearch(key: string): void
  onClear?(): void
  filters?: string[]
  containerClass?: string,
  data?: DynamicSectionsValue<DynamicSectionsError, ComponentSectionDynamicSearch>;
}

export const SearchBar: FC<SearchBarProps> = ({
  initialKeyword,
  onSearch,
  onClear,
  data,
  filters = ['Corso', 'Webinar', 'Ecommerce', 'Funnel', 'Pricing'],
}) => {
  const [keyword, setKeyword] = useState<string>(initialKeyword ?? '')

  const { logSearchCategory, logSearchKeyword } = useAnalytics()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && keyword) {
      logSearchKeyword(keyword)
      onSearch(keyword)
    }
  }

  const title = data ? pipe(
    data,
    O.fold(
      () => 'Cosa vuoi imparare oggi?',
      (data) => pipe(
        data,
        E.fold(
          () => 'Cosa vuoi imparare oggi?',
          data => data.title !== '' ? data.title : 'Cosa vuoi imparare oggi?'
        )
      ) 
    )
  ) : 'Cosa vuoi imparare oggi?';

  const { borders, spacing, colors } = useTheme()

  return (
    <VerticalStack
      borderRadius={borders.radius.large}
      bg={colors.card_background}
      borderStyle='solid'
      borderColor={colors.card_border}
      borderWidth={borders.width.base}
      p={spacing.space_5}
      alignItems='center'
      justifyContent='center'
      mb={spacing.space_10}>
      <Title variant='heading2xl' mb={spacing.space_5} alignment='center'>
        {title}
      </Title>

      <Box mb={spacing.space_5} maxWidth='700px' width='100%'>
        <TextInput
          variant='dark'
          size='lg'
          onChange={onChange}
          value={keyword}
          onKeyPress={onKeyPress}
          placeholder='Cerca'
          leftComponent={
            <FontAwesomeIcon icon={faSearch} style={{ color: '#808080' }} fontSize={15} />
          }
          rightComponent={
            keyword ? (
              <button
                className={style.closeButton}
                onClick={() => {
                  setKeyword('')
                  if (onClear) {
                    onClear()
                  }
                }}>
                <FontAwesomeIcon icon={faTimesCircle} color='#808080' />
              </button>
            ) : (
              <></>
            )
          }
        />
      </Box>
      <Box width='100%'>
        <HorizontalScroll shadowColor={colors.card_background}>
          {filters.map((filter, index) => {
            return (
              <button
                key={`${index}${filter}`}
                className={style.filter}
                data-active={keyword.toLowerCase() === filter.toLowerCase()}
                onClick={() => {
                  if (keyword.toLowerCase() !== filter.toLowerCase()) {
                    setKeyword(filter)
                    logSearchCategory(filter)
                    logSearchKeyword(filter)
                    onSearch(filter)
                  } else {
                    setKeyword('')
                    if (onClear) {
                      onClear()
                    }
                  }
                }}>
                {filter}
              </button>
            )
          })}
        </HorizontalScroll>
      </Box>
    </VerticalStack>
  )
}
