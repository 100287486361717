import style from './appLayout.module.css'
import React, { useContext, useEffect, useState } from 'react'
import { Navigation } from '../../components/Navigation'
import { getPositionIndexByUrl } from '../../components/Navigation/utils'
import { MenuProviderContext } from '../../components/Navigation/menuContext'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import FormControl from 'react-bootstrap/FormControl'
import { useHistory } from 'react-router-dom'
import { Back } from '../../components/Back'
import useAnalytics from '../../analytics/useAnalytics'
import QueryString from 'query-string'
import { ContinueWatchingPopup } from '../../components/ContinueWatchingPopup'
import auth from '../../utils/auth'
import { useContinueWatchingPopup } from '../../components/ContinueWatchingPopup/useContinueWatchingPopup'
import { Text, HorizontalStack, useTheme } from '@learnn/designn'
import { ReactComponent as BackIcon } from '../../assets/images/icons/back_arrow.svg'
import { OpenAppBanner } from '../../components/OpenAppBanner'

export type AppLayoutProps = {
  hideTitle?: boolean
  showSearchbar?: boolean
  showLastProgress?: boolean
  hideMobileBackToMenuButton?: boolean /** Mobile back button is shown on pages without header (susy pages) */
  fillcontent?: boolean /* Full width content without horizontal margin */
  fullwidth?: boolean /* Full width content with small horizontal margin */
  fillScreen?: boolean
  disableMargin?: boolean
  hideSecondaryMenu?: boolean
  title?: string
  backButton?: boolean
}
export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  fullwidth,
  fillcontent,
  fillScreen,
  hideTitle,
  disableMargin,
  hideSecondaryMenu,
  showSearchbar,
  title: titleProp,
  backButton,
  hideMobileBackToMenuButton,
  showLastProgress = true,
}) => {
  const { state: navigationState, dispatch } = useContext(MenuProviderContext)
  const [keyword, setKeyword] = useState<string>('')
  const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false)
  const [forceHideLastProgress, setForceHideLastProgress] = useState<boolean>(false)
  const { currentState: continueWatchingPopupState } = useContinueWatchingPopup()
  const history = useHistory()
  const { logSearchKeyword } = useAnalytics()
  const { spacing } = useTheme()

  const [activeMacroIndex, activeSecondaryIndex] = navigationState.menuPosition
  const menuItem = navigationState.menu[activeMacroIndex]
  const title = titleProp
    ? titleProp
    : menuItem.subs !== undefined && menuItem.subs.length > 0
      ? menuItem.subs[activeSecondaryIndex].label
      : menuItem.label
  const urlParams = QueryString.parse(location.search)

  useEffect(() => {
    const menuPosition = getPositionIndexByUrl(navigationState.menu, location.pathname)
    dispatch({ menuPosition: menuPosition })
  }, [])

  useEffect(() => {
    const lpwts = parseInt(
      auth.getLastProgressWidgetTs() ? auth.getLastProgressWidgetTs().toString() : '0',
    )

    if (!lpwts) return

    // Show last progress widget only if the last progress is older than * hours
    const currentTime = new Date().getTime()
    const maxHours = 3
    const limitTime = maxHours * 60 * 60 * 1000

    setForceHideLastProgress(currentTime - lpwts < limitTime)
  }, [continueWatchingPopupState])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value)
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && keyword) {
      logSearchKeyword(keyword)
      history.push('/?find=' + keyword)
      //dispatch({ menuPosition: [0, 1] })
    }
  }

  const showSearchInput = showSearchbar && (!urlParams || !urlParams.find)

  return (
    <div className={style.container}>
      <Navigation hideSecondaryMenu={hideSecondaryMenu} />
      <div className={style.content}>
      <OpenAppBanner />
        {
          !(hideMobileBackToMenuButton || (title && !hideTitle)) && (
            <div className={style.backToMenuButton}>
            <HorizontalStack width='100%' flexDirection='row' alignItems='center' justifyContent='flex-start' padding={spacing.space_4}>
              <BackIcon
                style={{ width: '2rem', height: '2rem', cursor: 'pointer' }}
                onClick={() => dispatch({ mobileMenuStatus: 'opened' })}
              />
              <Text ml={spacing.space_4} fontWeight='bold'>Torna al Menu</Text>
            </HorizontalStack>
            </div>
          )
        }
        {title && !hideTitle && (
          <div
            className={cx([
              style.header,
              fullwidth ? style.fullwidth : null,
              fillcontent ? style.fillcontent : null,
            ])}>
            {backButton && (
              <Back className={style.backButton} backTo='/' onClick={() => history.goBack()} />
            )}
            {!(showSearchInput && showMobileSearch) && <p className={style.headerTitle}>{title}</p>}

            <div className={style.headerRight}>
              {showSearchInput && (
                <div className={cx([style.inputContainer, showMobileSearch ? style.show : ''])}>
                  <div id='search-input' className={style.inputIcon}>
                    <FontAwesomeIcon icon={faSearch} color='#808080' fontSize={15} />
                  </div>
                  <FormControl
                    className={style.input}
                    placeholder='Cosa vuoi imparare?'
                    aria-label='Cosa vuoi imparare?'
                    aria-describedby='search-input'
                    onChange={onChange}
                    value={keyword}
                    onKeyPress={onKeyPress}
                  />
                  <button
                    className={style.closeButton}
                    onClick={() => {
                      setKeyword('')
                      setShowMobileSearch(false)
                    }}>
                    <FontAwesomeIcon icon={faTimesCircle} color='#808080' />
                  </button>
                </div>
              )}
              <div
                className={cx([
                  style.mobileSearchMenuIcon,
                  navigationState.mobileMenuStatus && !showMobileSearch && showSearchInput
                    ? style.show
                    : '',
                ])}>
                <FontAwesomeIcon
                  icon={faSearch}
                  size='2x'
                  onClick={() => setShowMobileSearch(true)}
                />
              </div>
              <div
                className={cx([
                  style.mobileMenuIcon,
                  'ml-4',
                  navigationState.mobileMenuStatus ? style.show : '',
                ])}>
                <FontAwesomeIcon
                  icon={faBars}
                  size='2x'
                  onClick={() => dispatch({ mobileMenuStatus: 'opened' })}
                />
              </div>
            </div>
          </div>
        )}
        {showLastProgress && !forceHideLastProgress && <ContinueWatchingPopup />}
        <div
          className={cx([
            style.main,
            fullwidth ? style.fullwidth : null,
            disableMargin ? style.disableMargin : null,
            fillScreen && style.fillScreen,
            fillcontent && style.fillcontent,
          ])}>
          {children}
        </div>
      </div>
    </div>
  )
}
