import { useContext } from 'react'
import { getCourseVerifiedStatus } from '../../controllers/Quiz'
import { GlobalProviderContext } from '../GlobalProvider'
import { useQuery } from 'react-query'
import { CourseBaseInfo, CourseBaseInfoEngagement } from '@learnn/sdk/src/api/course'
import style from './coursePopup.module.css'

import { ReactComponent as VerifyIcon } from '../../assets/images/icons/verified.svg'
import { ReactComponent as VerifyOutlineIcon } from '../../assets/images/icons/verified_outline.svg'
import { ReactComponent as DownloadIcon } from '../../assets/images/icons/download.svg'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

type DetailsProps = {
  course: (CourseBaseInfo & Partial<CourseBaseInfoEngagement>)
}
export const Details = ({ course }: DetailsProps) => {
  const globalContext = useContext(GlobalProviderContext)
  const userId = globalContext?.userId
  const verifiedAvailabilityQuery = useQuery(['verifiedAvailability', course.id], () => getCourseVerifiedStatus(userId ?? '', course.id), { retry: false })


  const renderVerifiedStatus = () => {
    switch (verifiedAvailabilityQuery.status) {
      case 'success': {
        switch (verifiedAvailabilityQuery.data.status) {
          case 'passed': return (
            <div className={style.resource}>
              <VerifyIcon className={style.verifiedIcon} />
              Verifica ottenuta
            </div>
          )
          default: return (
            <div className={style.resource}>
              <VerifyOutlineIcon className={style.verifiedIcon} />
              Verifica disponibile
            </div>
          )
        }
      }
      case 'loading': return (
        <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
          <div style={{ width: '130px', height: '30px' }} >
            <Skeleton />
          </div>
        </SkeletonTheme>
      )
      case 'idle': return <></>
      case 'error': return <></>
    }

  }


  return (
    <div className={style.resourcesDetails} style={{ flex: 30 }}>
      {
        course.resources || verifiedAvailabilityQuery.status === 'success' ? (
          <h4 className={style.sectionTitle}>DETTAGLI</h4>
        ) : !course.resources && verifiedAvailabilityQuery.status === 'loading' ? (
          <SkeletonTheme baseColor='#252525' highlightColor='#2b2b2b'>
            <div style={{ width: '130px', height: '30px' }} >
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) : <></>
      }
      {
        renderVerifiedStatus()
      }
      {
        course.resources ? (
          <div className={style.resource}>
            <DownloadIcon className={style.resourceIcon} />
            Risorse scaricabili
          </div>
        ) : <></>
      }
    </div>
  )
}