import { extractThumbUrlFromImage, calculateLessonDuration, extractCourseAuthorAndProfession, } from '../utils';
import { progressiveSearch } from './progressiveSearch';
const hideDraft = (lesson) => !lesson.draft;
export const smartSearch = (client, oramaClient, getPath, engagement, courseFactory) => {
    const searchByKeyword = async (keyword, limit = 100) => {
        const { coursesIndex, lessonsIndex, pathsIndex } = oramaClient;
        let courses, lessons, paths;
        try {
            const pathsResult = await pathsIndex.search({
                term: keyword,
                limit: limit,
                mode: 'hybrid',
            });
            paths = pathsResult?.hits.map(x => x.document) ?? [];
        }
        catch (e) {
            paths = [];
        }
        try {
            const coursesResult = await coursesIndex.search({
                term: keyword,
                limit: limit,
                mode: 'hybrid',
            });
            courses = coursesResult?.hits.map(x => x.document) ?? [];
        }
        catch (error) {
            courses = [];
        }
        try {
            const lessonsResult = await lessonsIndex.search({
                term: keyword,
                limit: limit,
                mode: 'hybrid',
            });
            lessons = lessonsResult?.hits.map(x => x.document) ?? [];
        }
        catch (error) {
            lessons = [];
        }
        const draftCourses = await courseFactory.getAllDrafts();
        const filteredCourses = courses.filter((c) => !draftCourses.includes(c.id));
        const filteredLessons = lessons.filter((l) => !draftCourses.includes(l.courseId));
        return {
            courses: filteredCourses.map(toCourse),
            lessons: filteredLessons.map(toLesson),
            paths: paths.map(toPath),
        };
    };
    const toCourse = (c) => {
        return {
            ...c,
            id: String(c.id),
            type: c.type,
            authors: c.authors && c.authors.length > 0 ? c.authors.map(extractCourseAuthorAndProfession) : [],
            coverImage: extractThumbUrlFromImage(c.coverImage, 'medium') ?? '',
            companyLogo: extractThumbUrlFromImage(c.companyLogo, 'small') ?? '',
        };
    };
    const toLesson = (l) => {
        const { course, coverImage } = l;
        return {
            ...l,
            id: String(l.id),
            coverImageUrl: extractThumbUrlFromImage(coverImage, 'medium') ?? '',
            duration: calculateLessonDuration(l.duration ?? 0),
            durationSeconds: l.duration,
            category: course?.title ?? 'LEZIONE',
            free: l.free ?? false,
        };
    };
    const toPath = (p) => {
        return {
            ...p,
            image: extractThumbUrlFromImage(p.image[0], 'large'),
        };
    };
    return {
        searchByKeyword: searchByKeyword,
        progressiveSearch: progressiveSearch(searchByKeyword, getPath, engagement),
    };
};
