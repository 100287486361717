import { Menu } from './Menu'

export const getPositionIndexByUrl = (menu: Menu, _path: string): [number, number] => {
  let path = _path.charAt(_path.length - 1) === '/' ? _path.slice(0, -1) : _path
  do {
    for (const [mIndex, mItem] of menu.entries()) {
      if (mItem.subs)
        for (const [sIndex, sItem] of mItem.subs.entries()) {
          if (sItem.type === 'item' && sItem.url === path) return [mIndex, sIndex]
        }
      else if (mItem.url) {
        if (mItem.url === path) return [mIndex, 0]
      }
    }
    path = path.trim().substring(0, path.trim().lastIndexOf('/'))
  } while (path !== '')
  return [0, 0]
}
