import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { TextInput, useTheme } from '@learnn/designn'

function InputPassword({ onChange, onBlur, value, placeholder, name, testId }) {
  const { spacing, colors } = useTheme()
  const [showInput, setShowInput] = useState(false)
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <TextInput
        data-test-id={testId}
        size='xl'
        type={showInput ? 'text' : 'password'}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        borderColor={colors.outline}
        borderWidth={spacing.space_035}
      />
      <FontAwesomeIcon
        icon={showInput ? faEye : faEyeSlash}
        onClick={() => setShowInput(!showInput)}
        style={{ marginLeft: '-10%', cursor: 'pointer', color: '#707070' }}
      />
    </div>
  )
}

export default InputPassword
