import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Profile as ProfileType } from '@learnn/sdk/src/api/profile'
import { Box, Button, Text, HorizontalStack, VerticalStack } from '@learnn/designn'
import { updateProfile } from '../../controllers/Profile'
import { ApolloError } from '@apollo/client'
import { colors } from '@learnn/designn/build/theme/tokens/colors'
import { spacing } from '@learnn/designn/build/theme/tokens/spacing'
import { useState, useRef, useEffect } from 'react'
import { ReminderEditor } from '../../components/Profile/ReminderEditor'

const ReminderSchema = Yup.object().shape({
  reminderTime: Yup.string(),
  reminderDays: Yup.array().of(Yup.string()),
})
export const Reminder = ({ profile }: { profile?: ProfileType }) => {
  const [isSubmitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState<null | string>(null)
  const [success, setSuccess] = useState<false | string>(false)
  const formRef = useRef<FormikProps<any>>(null)

  const handleSubmit = async (values: ProfileType) => {
    setSubmitLoading(true)
    setError(null)
    setSuccess(false)
    try {
      await updateProfile(values)

      setSuccess('Profilo aggiornato correttamente')
      setSubmitLoading(false)
    } catch (error: any) {
      if (error.name === 'ApolloError') {
        const apolloError = error as ApolloError
        const errorCode = JSON.parse(apolloError.message).code
        switch (errorCode) {
          case 'UsernameAlreadyExistsError': {
            formRef?.current?.setFieldError('nickname', 'Username non disponibile')
            break
          }
          default: {
            setError('Si è verificato un errore imprevisto')
          }
        }
      } else {
        setError('Si è verificato un errore imprevisto')
      }
      setSubmitLoading(false)
    }
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false)
      }, 3000)
    }
  }, [success])

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        reminderTime: profile?.reminderTime ?? undefined,
        reminderDays: profile?.reminderDays ?? [],
      }}
      validationSchema={ReminderSchema}
      onSubmit={handleSubmit}>
      {formik => {
        const SubmitButton = ({size}: {size: 'sm' | 'lg'}) => {
          return (
            <VerticalStack flex={3} mt={{ _: spacing.space_4, medium: 0 }}>
              <Button
                variant='primary'
                label='Salva modifiche'
                size={size}
                loading={isSubmitLoading}
                onPress={formik.handleSubmit}
              />
              {error && (
                <Box mt={spacing.space_2}>
                  <Text variant='bodyXs' textColor={colors.text.error} alignment='center'>
                    {error}
                  </Text>
                </Box>
              )}
              {success && (
                <Box mt={spacing.space_2}>
                  <Text variant='bodyXs' textColor={colors.text.success} alignment='center'>
                    {success}
                  </Text>
                </Box>
              )}
            </VerticalStack>
          )
        }

        return (
          <VerticalStack gap={spacing.space_4} maxWidth='900px'>
            <HorizontalStack justifyContent='flex-end'>
              <HorizontalStack
                justifyContent='flex-end'
                display={{ _: 'none !important', large: 'flex !important' }}>
                <SubmitButton size='sm' />
              </HorizontalStack>
            </HorizontalStack>

            <ReminderEditor formik={formik} profile={profile} />

            <HorizontalStack display={{ _: 'flex !important', large: 'none !important' }}>
              <SubmitButton size='lg' />
            </HorizontalStack>
          </VerticalStack>
        )
      }}
    </Formik>
  )
}
