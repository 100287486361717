import api from "./api";

export const getJobs = async () => {
  try {
    const { comingcontents } = await api;

    const contents = await comingcontents.get();

    return contents.jobs;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
