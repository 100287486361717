import { IFrame } from '../../components/IFrame/IFrame'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

export const MakeLeaderboardScreen = () => {
  return (
    <AppLayout fullwidth disableMargin hideTitle>
      <IFrame url='https://learnn.com/int/make/rank/' fullHeight />
    </AppLayout>
  )
}
