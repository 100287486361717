import { CoursePopupContext } from './popupContext'
import { useCoursePopup } from './useCoursePopup'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useShare } from '../SharePopup/useShare'
import { getCourseHighlight } from '../../controllers/Course'

import { useQueryClient } from 'react-query'

import { ResponsiveImage } from '../ResponsiveImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../Button'
import { MyLearnnButton } from '../MyLearnnButton'

import { ReactComponent as ShareIcon } from '../../assets/images/icons/share.svg'
import { ReactComponent as ClockIcon } from '../../assets/images/icons/clock.svg'
import { ReactComponent as LessonsIcon } from '../../assets/images/icons/lessons.svg'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'
import { SimplePlayer } from '../../components/SimplePlayer/SimplePlayer'

import style from './coursePopup.module.css'
import env from '../../env.json'
import { CourseHighlight } from '@learnn/sdk/src/api/course'
import { Details } from './Details'

export const CoursePopup = () => {
  const { state } = useContext(CoursePopupContext)
  const { close } = useCoursePopup()
  const { share } = useShare()
  const history = useHistory()
  const queryClient = useQueryClient()
  const [loadingHighlight, setLoading] = useState(true)
  const [highlight, setHighlight] = useState<CourseHighlight>()

  const goToCourse = (id: string) => {
    close()
    history.push(`/corso/${id}`)
  }

  const getHighlight = async (id: string) => {
    try {
      setLoading(true)
      const highlight = await queryClient.fetchQuery(['highlight', id], {
        queryFn: () => getCourseHighlight(id),
      })
      setHighlight(highlight)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (state.show && state.course.id) {
      getHighlight(state.course.id)
    }
  }, [state.show])

  switch (state.show) {
    case true: {
      return (
        <div
          className={style.popupContainer}
          onClick={event => {
            event.preventDefault()
            close()
          }}>
          <div className={style.content}>
            {state.course.companyLogo ? (
              <img className={style.companyLogo} src={state.course.companyLogo} />
            ) : null}
            <CloseIcon className={style.closeIcon} onClick={() => close()} />
            <div
              className={style.box}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}>
              <div className={style.card}>
                <div className={style.imageContainer}>
                  {loadingHighlight ? (
                    <div style={{ minHeight: 340, aspectRatio: 16 / 9, width: '100%' }} />
                  ) : (
                    <>
                      {highlight?.highlightVideoUrl ? (
                        <SimplePlayer
                          url={highlight?.highlightVideoUrl}
                          playerClassName={style.simplePlayer}
                        />
                      ) : highlight?.trailerVideoUrl ? (
                        <SimplePlayer
                          url={highlight?.trailerVideoUrl}
                          playerClassName={style.simplePlayer}
                        />
                      ) : (
                        <ResponsiveImage
                          wrapperClassName={style.borderedImage}
                          src={state.course.coverImage}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className={style.cardBody}>
                  <div className={style.cardHeader}>
                    <div className={style.badges}>
                      <div className={style.contentType}>{state.course.type.title}</div>
                      {state.course.duration ? (
                        <div className={style.detailBadge}>
                          <ClockIcon className={style.deailIcon} />
                          <div>{state.course.duration}</div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {state.course.lessonsCount ? (
                        <div className={style.detailBadge}>
                          <LessonsIcon className={style.deailIcon} />
                          <div>
                            {`${state.course.lessonsCount} LEZION${
                              state.course.lessonsCount > 1 ? 'I' : 'E'
                            }`}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <p className={style.title}>{state.course.title}</p>
                  </div>
                  {state.course.description ? (
                    <p className={style.description}>
                      {`${state.course.description.slice(0, 130)}...`}
                      <a onClick={() => goToCourse(state.course.id)} className={style.readMore}>
                        vedi altro.
                      </a>
                    </p>
                  ) : (
                    <></>
                  )}
                  <div className={style.detailsContainer}>
                    <div style={{ flex: 70 }}>
                      <h4 className={style.sectionTitle}>TENUTO DA</h4>
                      <div className={style.authorDetails}>
                        <img
                          src={state.course.authors[0].avatar ?? state.course.coverImage}
                          className={style.authorImage}
                        />
                        <div className={style.authorName}>
                          <h4>{state.course.authors[0].authorName}</h4>
                          <p>{state.course.authors[0].profession}</p>
                        </div>
                      </div>
                    </div>
                    <Details course={state.course} />
                  </div>
                  <div className={style.buttonsContainer}>
                    <Button className={style.mainCta} onClick={() => goToCourse(state.course.id)}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        <span>Esplora il {state.course.type.title}</span>
                        <FontAwesomeIcon icon={faArrowRight} className={style.mainCtaIcon} />
                      </div>
                    </Button>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <Button
                        className={style.circularButton}
                        onClick={() =>
                          share({
                            title: 'Regala 7 giorni PRO gratis e ottieni crediti',
                            type: state.course.type.slug,
                            url: `${env.SITE_URL}/corso/${state.course.id}`,
                          })
                        }>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}>
                          <ShareIcon className={style.shareIcon} />
                        </div>
                      </Button>
                      <MyLearnnButton
                        itemType='course'
                        itemId={state.course.id}
                        myLearnnId={state.course.myLearnnId}
                        containerClass={style.myLearnnButton}
                        className={style.circularButtonIcon}
                        addIconColor='light'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    default: {
      return <></>
    }
  }
}
