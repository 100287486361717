import { IFrame } from '../../components/IFrame/IFrame'
import { AppLayout } from '../../layouts/AppLayout/AppLayout'

export const ExpertTrovaScreen = () => {
  return (
    <AppLayout fullwidth disableMargin hideTitle hideSecondaryMenu>
      <IFrame url='https://learnn.com/expert/' fullHeight />
    </AppLayout>
  )
}
