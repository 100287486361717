import { gql } from '@apollo/client';
import { extractThumbUrlFromImage, getTextDate, getObjectDate, normalizeGraphqlResponse, DEFAULT_WALL_LIMIT, } from '../utils';
export const comingcontents = (client) => {
    const getComingContents = async () => {
        const result = await client
            .query({
            query: COMING_CONTENTS,
        })
            .then(normalizeGraphqlResponse);
        const comingContents = result.data?.comingContents.map((f) => {
            return {
                ...f,
                releaseDate: getTextDate(f.releaseDate),
                releaseDateObject: getObjectDate(f.releaseDate, 'short'),
                releaseDateString: f.releaseDate,
                ...{ coverImageUrl: extractThumbUrlFromImage(f.coverImage, 'large') },
            };
        });
        const inArrivo = comingContents.filter(function (c) {
            return c.type === 'in_arrivo';
        });
        const jobs = comingContents
            .filter(function (c) {
            return c.type === 'jobs';
        })
            .reverse();
        const contents = { inArrivo, jobs };
        return contents;
    };
    return {
        get: getComingContents,
    };
};
const COMING_CONTENTS = gql `
  query ComingContents {
    comingContents(sort: "releaseDate:asc", filters: { hide: {eq: false} }, pagination: {pageSize: ${DEFAULT_WALL_LIMIT}}) {
      data {
        id
        attributes {
          title
          shortTitle
          type
          description
          releaseDate
          coverImage {
            data {
              attributes {
                formats
            		urlCdn
              }
            }
          }
          author {
            data {
              attributes {
                firstName
            		lastName
              }
            }
          }
          categories {
            data {
              attributes {
                title
              }
            }
          }
          contentUrl
        }
        }
      }
  }
`;
