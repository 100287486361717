import { FC, useState } from 'react'
import style from './searchResults.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { CourseBaseInfoEngagement, CourseType } from '@learnn/sdk/dist/api/course'
import { CardsGrid } from '../CardsGrid/CardsGrid'
import { CourseCard } from '../CourseCard'
import { ProgressiveSearchContent } from '../../controllers/Search'
import { useCoursePopup } from '../CoursePopup/useCoursePopup'
import { useEffect } from 'react'
import { chunkArray, getUrlFromLesson } from '../../utils/data'
import { handleContextMenu } from '../../utils/contextMenu'
import { LessonCard } from '../../components/LessonCard'
import { useHistory } from 'react-router-dom'
import { ContextMenu } from '../../components/ContextMenu'
import { PathListItem } from '../../screens/PathScreen/components/PathListItem'
import { VerticalStack } from '@learnn/designn'
import { CoursesCarousel } from '../CoursesCarousel/CoursesCarousel'
import { LessonsCarousel } from '../LessonsCarousel'
import { PathsCarousel } from '../PathsCarousel/PathsCarousel'

export type SearchResultProps = {
  data: ProgressiveSearchContent
  onResultClick(course: CourseBaseInfoEngagement): void
  onClear?(): void
}

export const SearchResults: FC<SearchResultProps> = ({ data, onClear, onResultClick }) => {
  const [filterCategory, setFilterCategory] = useState<'all' | CourseType | 'lessons' | 'paths'>('all')
  const history = useHistory()

  const { show } = useCoursePopup()

  if (!data.all.length && !data?.lessons?.length && !data?.paths?.length) {
    return (
      <div className={style.containerNoResult}>
        <h3 className={style.noResultTitle}>Nessun contenuto trovato</h3>
        <p className={style.noResultText}>
          Prova a modificare la tua ricerca utilizzando una keyword più ampia
        </p>
      </div>
    )
  }

  useEffect(() => {
    if (data.all.length > 0) setFilterCategory('all')
    else if (data.courses.length > 0) setFilterCategory('course')
    else if (data.webinars.length > 0) setFilterCategory('webinar')
    else if (data.lessons.length > 0) setFilterCategory('lessons')
    else if (data.paths.length > 0) setFilterCategory('paths')
  }, [])

  const handleCourseClick = (course: CourseBaseInfoEngagement) => {
    if (onResultClick) onResultClick(course)

    show(course)
  }

  const renderSection = () => {
    switch (filterCategory) {
      case 'all':
        return (
          <VerticalStack>
            {
              data.paths.length > 0 && (
                <PathsCarousel
                  paths={data.paths}
                  title='Percorsi'
                  onShowAll={() => setFilterCategory('lessons')}
                  onPathClick={(path) => history.push(`/paths/${path.slug}`)}
                />
              )
            }
            {
              data.courses.length > 0 && (
                <CoursesCarousel
                  courses={data.courses}
                  title='Corsi'
                  onShowAll={() => setFilterCategory('course')}
                  onCourseClick={(course) => handleCourseClick(course as CourseBaseInfoEngagement)}
                  containerClass={style.carouselContainer}
                />
              )
            }
            {
              data.webinars.length > 0 && (
                <CoursesCarousel
                  courses={data.webinars}
                  title='Webinars'
                  onShowAll={() => setFilterCategory('webinar')}
                  onCourseClick={(course) => handleCourseClick(course as CourseBaseInfoEngagement)}
                  containerClass={style.carouselContainer}
                />
              )
            }
            {
              data.lessons.length > 0 && (
                <LessonsCarousel
                  lessons={chunkArray(data.lessons, 3)}
                  title='Lezioni'
                  titleClass={style.sectionTitle}
                  onShowAll={() => setFilterCategory('lessons')}
                  myLearnn
                />
              )
            }


          </VerticalStack>

        )
      case 'course':
        return (
          <CardsGrid type='courses'>
            {data.courses.map(course => (
              <CourseCard
                key={`course_result_${course.id}`}
                course={course}
                myLearnn={true}
                onClick={() => handleCourseClick(course)}
                myLearnnId={course.myLearnnId}
              />
            ))}
          </CardsGrid>
        )
      case 'webinar':
        return (
          <CardsGrid type='courses'>
            {data.webinars.map(course => (
              <CourseCard
                key={`webinar_result_${course.id}`}
                course={course}
                myLearnn={true}
                onClick={() => handleCourseClick(course)}
                myLearnnId={course.myLearnnId}
              />
            ))}
          </CardsGrid>
        )
      case 'lessons':
        return (
          <CardsGrid type='lessons'>
            {data.lessons.map(lesson => (
              <LessonCard
                wide
                myLearnn
                myLearnnId={lesson.myLearnnId}
                lesson={lesson}
                onClick={() => history.push(getUrlFromLesson({ id: lesson.id }))}
                onContextMenu={e =>
                  handleContextMenu(e, { url: getUrlFromLesson({ id: lesson.id }) })
                }
              />
            ))}
            <ContextMenu menuId={`default`} />
          </CardsGrid>
        )
      case 'paths':
        return (
          <CardsGrid type='path'>
            {data.paths.map(path => (
              <PathListItem path={path} onClick={() => history.push(`/paths/${path.slug}`)} />
            ))}
            <ContextMenu menuId={`default`} />
          </CardsGrid>
        )
    }
  }
  return (
    <div>
      <div className={style.barContainer}>
        <div className={style.filtersContainer}>
          {data.courses.length > 0 && data.webinars.length > 0 && (
            <button
              className={style.filter}
              data-active={filterCategory === 'all'}
              onClick={() => setFilterCategory('all')}>
              Tutti
            </button>
          )}
          {data.paths.length > 0 && (
            <button
              className={style.filter}
              data-active={filterCategory === 'paths'}
              onClick={() => setFilterCategory('paths')}>
              Percorsi
            </button>
          )}
          {data.courses.length > 0 && (
            <button
              className={style.filter}
              data-active={filterCategory === 'course'}
              onClick={() => setFilterCategory('course')}>
              Corsi
            </button>
          )}
          {data.webinars.length > 0 && (
            <button
              className={style.filter}
              data-active={filterCategory === 'webinar'}
              onClick={() => setFilterCategory('webinar')}>
              Webinar
            </button>
          )}
          {data.lessons.length > 0 && (
            <button
              className={style.filter}
              data-active={filterCategory === 'lessons'}
              onClick={() => setFilterCategory('lessons')}>
              Lezioni
            </button>
          )}
          <div className={style.rightShadow}></div>
        </div>
      </div>
      <h4
        onClick={() => {
          if (onClear) {
            onClear()
          }
        }}
        className={style.removeText}>
        <FontAwesomeIcon icon={faChevronLeft} color='#FFFFFF' style={{ marginRight: 5 }} />
        Rimuovi i filtri
      </h4>
      <div style={{ marginBottom: '50px' }}>{renderSection()}</div>
    </div>
  )
}
