import styles from './styles/pathCertificateItem.module.css'
import { FC, useState } from 'react'
import cx from 'classnames'
import { ResponsiveImage } from '../../../components/ResponsiveImage'
import trophyImage from '../../../assets/images/trophy.svg'
import { Button } from '../../../components/Button'
import { generatePathAttempt, getPathAttempt } from '../../../controllers/Quiz'
import { PathWithProgress } from '../../../controllers/Paths'
import { PathAttemptData } from '@learnn/sdk/dist/api/quiz'
import Loader from 'react-loader-spinner'
import { useQuery } from 'react-query'
import { matchQuery } from '../../../utils/matchQuery'
import { HorizontalStack, Title, VerticalStack, useTheme } from '@learnn/designn'
import { CircularProgress } from '../../../components/CircularProgress'

export type PathStatus = 'loading' | 'in_progress' | 'to_verify' | 'verified'
export type PathCertificateItemProps = {
  containerStyle?: string
  onClick?: () => unknown
  onCertificationReceived?: (attempt: PathAttemptData) => unknown
  status: PathStatus
  path: PathWithProgress
  userId: string
  progress?: number
  /**
   * Classic for Item in course list, Sidebar for summary under sidebar details
   */
  type?: 'classic' | 'sidebar'
}
export const PathCertificateItem: FC<PathCertificateItemProps> = ({
  containerStyle,
  onClick,
  onCertificationReceived,
  status,
  path,
  userId,
  progress,
  type = 'classic',
}) => {
  const attemptQuery = useQuery(['path-attempt', path.id], () => getPathAttempt(userId, path.id), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
  const [loading, setLoading] = useState(false)
  const { spacing } = useTheme()

  if (status === 'loading') return <></>

  console.log(status)

  return (
    <>
      {matchQuery(attemptQuery, {
        loading: () => (
          <div className={styles.containerLoading}>
            <Loader type='ThreeDots' color='#FFFFFF' height={50} width={50} />
          </div>
        ),
        success: (attempt: PathAttemptData | null) => {
          if (attempt) status = 'verified'

          switch (status) {
            case 'loading':
              return <></>
            case 'in_progress':
              return (
                <HorizontalStack
                  borderRadius='20px'
                  background='linear-gradient(270deg, #2a272c 49.04%, rgba(39, 39, 44, 0.52) 100%)'
                  p={spacing.space_5}
                  justifyContent='center'
                  alignItems='center'
                  mt='30px'>
                  <VerticalStack mr={spacing.space_5}>
                    <CircularProgress
                      progress={progress}
                      size={80}
                      labelSize={'20px'}
                      trackWidth={3}
                      indicatorWidth={3.5}
                    />
                  </VerticalStack>
                  <VerticalStack flex={1}>
                    <Title variant='headingSm'>
                      Completa le verifiche di tutti i corsi per ottenere la verifica del percorso!
                    </Title>
                  </VerticalStack>
                  {type === 'classic' && (
                    <VerticalStack width='150px'>
                      <ResponsiveImage wrapperClassName={styles.image} src={trophyImage} />
                    </VerticalStack>
                  )}
                </HorizontalStack>
              )
            case 'to_verify':
              return (
                <div className={cx([styles.container, containerStyle])} onClick={onClick}>
                  {type === 'classic' && (
                    <div className={styles.imageContainer}>
                      <ResponsiveImage wrapperClassName={styles.image} src={trophyImage} />
                    </div>
                  )}
                  <div className={styles.content}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div className={styles.title}>Complimenti, hai verificato tutti i corsi!</div>
                      <Button
                        className={styles.quizButton}
                        disabled={loading}
                        onClick={async () => {
                          setLoading(true)
                          const attemptPath = await generatePathAttempt(path.id)
                          onCertificationReceived && onCertificationReceived(attemptPath)
                          await attemptQuery.refetch()
                        }}>
                        <p className={styles.quizButtonText}>Riscatta la Verifica</p>
                      </Button>
                    </div>
                  </div>
                </div>
              )
            case 'verified': {
              if (!attempt) return <></>
              if (type === 'sidebar') return <></>
              return (
                <div className={cx([styles.container, containerStyle])} onClick={onClick}>
                  <div className={styles.imageContainer}>
                    <ResponsiveImage wrapperClassName={styles.image} src={trophyImage} />
                  </div>
                  <div className={styles.content}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className={styles.title}>
                        Complimenti, hai ottenuto la Verifica del percorso!
                      </div>
                      <Button
                        className={styles.quizButton}
                        disabled={loading}
                        onClick={async () => {
                          window.open(`https://learnn.com/v/${attempt.attemptId}`, '_blank')
                        }}>
                        <p className={styles.quizButtonText}>Visualizza</p>
                      </Button>
                    </div>
                  </div>
                </div>
              )
            }
          }
        },
        error: () => <></>,
      })}
    </>
  )
}
