import { useContext, useRef, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { LessonPlayer } from '../../components/LessonPlayer'
import { ErrorMessage } from '../../components/ErrorMessage'
import { Back } from '../../components/Back'
import { TextLessonPlayer } from '../../components/TextLessonPlayer'
import { useBodyClass } from '../../utils/hooks'
import { getLesson, getLessonPreview } from '../../controllers/Player'
import { GlobalProviderContext } from '../../components/GlobalProvider'
import { extractConfiguration } from '../../utils/data'
import env from '../../env.json' //@ts-ignore
import Loader from 'react-loader-spinner'
import style from './playerScreen.module.css'
import QueryString from 'query-string'

interface IPlayerScreenProps {
  children?: any
}

interface ILocationState {
  noteOpened?: boolean
  startingRate?: number
  subtitlesVisible?: boolean
}

type UrlParams = {
  t?: number
  play?: string
}

export const PlayerScreen = (_: IPlayerScreenProps) => {
  const globalContext = useContext(GlobalProviderContext)
  const blockRef = useRef<HTMLDivElement>()
  const { lessonId } = useParams<{ lessonId: string }>()
  const { state } = useLocation<ILocationState>()
  const configuration = extractConfiguration(globalContext)
  const history = useHistory()

  const queryClient = useQueryClient()
  const {
    isLoading: isLoadingPreview,
    error: previewError,
    data: lessonPreview,
  } = useQuery(['lessonPreview', lessonId], () => getLessonPreview(lessonId))
  const {
    isLoading,
    isFetched,
    error,
    data: lesson,
    refetch,
  } = useQuery(['lesson', lessonId], () => getLesson(lessonId))

  const urlParams: UrlParams = QueryString.parse(location.search, { parseNumbers: true })
  const noteOpened = state?.noteOpened
  const playbackRate = state?.startingRate
  const subtitlesVisible = state?.subtitlesVisible

  useBodyClass('app')

  const setBlockRef = (e: HTMLDivElement) => {
    if (e) {
      blockRef.current = e
      e.click()
    }
  }

  useEffect(() => {
    window.addEventListener('cfReady', () => {
      if (blockRef.current) {
        blockRef.current.click()
      }
    })
    return () => {
      window.removeEventListener('cfReady', () => {
        if (blockRef.current) {
          blockRef.current.click()
        }
      })
      if (lesson) {
        queryClient.invalidateQueries(['lesson', lessonId])
        queryClient.invalidateQueries(['lastProgresses'])
        queryClient.invalidateQueries(['course', lesson.courseSummary.course_id])
      }
    }
  }, [])

  useEffect(() => {
    if (!lessonPreview) {
      return
    }
    const blocked = !configuration?.premiumVisible && !lessonPreview.free
    if (!blocked && !isFetched) {
      refetch()
    }
  }, [lessonPreview])

  if (isLoading || isLoadingPreview) {
    return (
      <div className={style.container}>
        <Loader type='TailSpin' color='#FFFFFF' height={60} width={60} />
      </div>
    )
  }

  if (error || previewError || !lessonPreview) {
    return (
      <>
        <Back
          className={style.close}
          backTo={`/corso/${lesson?.courseSummary.course_id}`}
          onClick={() => {
            if (history.action !== 'POP') history.goBack()
            else history.push(`/corso/${lesson?.courseSummary.course_id}`)
          }}
        />
        <div className={style.errorContainer}>
          <ErrorMessage />
        </div>
      </>
    )
  }

  if (!configuration?.premiumVisible && !lessonPreview.free) {
    return (
      <div
        className={style.container}
        style={{ backgroundImage: `url(${lessonPreview.imageUrl})` }}>
        <div ref={setBlockRef} className={env.PRO_LESSON_PLAYER_CLASS}></div>
      </div>
    )
  }

  return lesson ? (
    <>
      {lesson.contentType === 'media' ? (
        <div className='responsiveContainer'>
          <LessonPlayer
            courseSummary={lesson.courseSummary}
            courseInfo={{
              lessonName: lesson.title,
              lessonTags: lesson.tags,
              courseName: lesson.courseSummary.course_title,
              myLearnnId: lesson.myLearnnId,
              lessonDuration: lesson.duration,
              lessonCompleted: lesson.courseSummary.lesson_completed,
              moduleId: lesson.courseSummary.module_id,
              lessonId,
              courseId: lesson.courseSummary.course_id,
              description: lesson.description,
              circlePostUrl: lesson.circlePostUrl
                ? lesson.circlePostUrl
                : lesson.courseSummary.circlePostUrl
                ? lesson.courseSummary.circlePostUrl
                : undefined,
              nextLessonId: lesson.nextLessonId,
              currentViewTime: urlParams?.t ? urlParams.t : lesson.currentViewTime,
              maxViewTime: lesson.maxViewTime,
              likeState: lesson.likeState,
              subtitles: lesson.subtitles,
            }}
            subtitles={lesson.subtitles}
            initialProps={{
              playing: urlParams?.play === 'true' ? true : false,
              url: lesson.videoUrl,
              ...(noteOpened && { noteOpened }),
              ...(playbackRate && { playbackRate }),
              ...(subtitlesVisible && { subtitlesVisible }),
              ...(urlParams?.t && { playedSeconds: urlParams?.t, played: urlParams?.t }),
            }}
            signedCookies={lesson.cookies.signedCookies}
            cookieExpires={lesson.cookies.cookieExpires}
            showCompletedWidget={urlParams?.t ? false : true}
          />
        </div>
      ) : (
        <TextLessonPlayer
          courseSummary={lesson.courseSummary}
          lesson={lesson}
          initialProps={{
            playing:
              urlParams?.play === 'true' && !lesson.courseSummary.lesson_completed ? true : false,
            url: lesson.videoUrl,
            ...(noteOpened && { noteOpened }),
            ...(playbackRate && { playbackRate }),
            ...(urlParams?.t && { playedSeconds: urlParams?.t, played: urlParams?.t }),
          }}
        />
      )}
    </>
  ) : null
}
